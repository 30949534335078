import '../App.css';
import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
//import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import { Link } from "react-router-dom";
//import { useTranslation } from 'react-i18next'

import { FaPhoneAlt } from 'react-icons/fa';
import { RiMailFill } from "react-icons/ri";
import { Nav } from 'react-bootstrap';


function Header() {
  //const [t, i18n] = useTranslation('global');
  return (
    <>
    
      <Navbar fixed="top"  >
          <div className="justify-content-end Nav-top_bar-bg Nav-top_bar only-desktop ">
            <ul className="List-no-style d-flex content-custom">
              <li>            
                <a href="tel:+1-800-456-478-23"><FaPhoneAlt /> +1-800-456-478-23</a>
              </li>
              <li>
                <a href="mail:info@Lightbox.com"><RiMailFill /> info@Lightbox.com</a>
              </li>
              {/*<li>
                <NavDropdown className="Dropmenu-lng" title="Idioma" id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={() => i18n.changeLanguage('es')}> Es</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => i18n.changeLanguage('en')}> En</NavDropdown.Item>
                </NavDropdown>
              </li>*/}
            </ul>
          </div>
     
        <Container className="Nav-bottom_bar">
          <Link to="/">
            <Navbar.Brand >
              <img width="130px"
                    className="d-inline-block align-top" 
                    src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633457140/Lightbox/LOGOTYPE_LIGHTBOX_weenp2.svg" alt="" />
            </Navbar.Brand>
          </Link>
          <Navbar className="nav-mobile" collapseOnSelect expand="lg" >
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav activeKey="/home" className="me-auto justify-content-end">
                <Nav.Item>
                  <Nav.Link><Link to="/">Home</Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link><Link to="/blog">Blog</Link></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link><Link to="/philosofy">Philosophy</Link></Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </Navbar>
      
    </>
  );
}

export default Header;
