import '../App.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { FaFacebookSquare,FaTwitterSquare,FaInstagramSquare,FaLinkedin } from "react-icons/fa";


function Footer() {
  const [t, i18n] = useTranslation('global');

  return (
    <div className="bg-container-grey-h">
      <Container className="footer">
        {/*<div className="footer-img">
          <img width="130px" src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632434614/aesir/AESIR_LOGOTYPE_web_01_ilordo.png" alt="" />
        </div>*/}

        <div className="List-no-style only-mobile padd-space ">
          <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/AESIR-Corporation-103167094687916"><span className="s-icon"><FaFacebookSquare /></span></a>
          <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/aesircorp_"><span className="s-icon"><FaTwitterSquare /></span></a>
          <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/aesircorporation/"><span className="s-icon"><FaInstagramSquare /></span></a>
          <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/%C3%A6sir-corporation"><span className="s-icon"><FaLinkedin /></span></a>
        </div>
        <div className="only-mobile padd-space">
          <ul className="List-no-style">
            <li><a href="mail:info@aesir.net">info@Lightbox.net</a></li>
            <li><a href="tel:+52 1 55 6413 0164">+52 1 55 6413 0164</a></li>
          </ul>
        </div>
        
        <Accordion >
          <div className="padd-space">
            <Row>
              <Col className="padd-space" xs={12} md={4} lg={3}>
                <Accordion.Item eventKey="0">
                  <Accordion.Header><h6>{t('footer.about-us')}</h6></Accordion.Header>
                  <Accordion.Body>
                    <ul className="List-no-style">
                      <li><Link to="/philosofy">Philosophy</Link></li> 
                      {/*<li>Join the team!</li>*/}
                      <li><Link to="/blog">Blog</Link></li>
                      {/* <li>Press Release</li> */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Col>

              <Col className="padd-space" xs={12} md={4} lg={3}>
                <Accordion.Item eventKey="1">
                  <Accordion.Header><h6>Politics</h6></Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <ul className="List-no-style">
                        <li><Link to="/anti-corruption-policy">Anti-corruption Policy</Link></li>
                        <li><Link to="/code-of-ethics">Code Of Ethics</Link></li>
                        <li><Link to="/cookies-policy">Cookies Policy</Link></li>
                        <li><Link to="/enviromental-policy">Environmental Policy</Link></li>
                        <li><Link to="/information-security-policies">Information Security Policies</Link></li>
                        {/* <li>Investor Relations</li> */}
                        <li><Link to="/human-rights">Human Rights</Link></li>
                      </ul>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Col>

              <Col className="padd-space" xs={12} md={4} lg={3}>
                <Accordion.Item eventKey="2">
                  <Accordion.Header><h6>Where we are?</h6></Accordion.Header>
                  <Accordion.Body>
                    <ul className="List-no-style">
                      <li>México</li>
                      <li>Spain</li>
                      <li>Chile</li>
                      <li>Colombia</li>
                      <li>Canada</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Col>

              <Col className="padd-space only-desktop" xs={12} md={4} lg={3}>
                <h6>Contact Us</h6>
                <ul className="List-no-style">
                  <li><a href="mail:info@Lightbox.net">info@Lightbox.net</a></li>
                  <li><a href="tel:+52 1 55 6413 0164">+52 1 55 6413 0164</a></li>
                </ul>
                <div className="List-no-style">
                  <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/AESIR-Corporation-103167094687916"><span className="s-icon"><FaFacebookSquare /></span></a>
                  <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/aesircorp_"><span className="s-icon"><FaTwitterSquare /></span></a>
                  <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/aesircorporation/"><span className="s-icon"><FaInstagramSquare /></span></a>
                  <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/%C3%A6sir-corporation"><span className="s-icon"><FaLinkedin /></span></a>
                </div>
              </Col>
            </Row>
          </div>
        </Accordion>

        <Container>
          <hr className="only-desktop" />
        </Container>

        <div className="padd-space padd-md-bottom only-mobile ">
          <img width="130px" src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633457140/Lightbox/LOGOTYPE_LIGHTBOX_weenp2.svg" alt="" />
        </div>

        <div>
          <h5>Copyright © 2021 Lightbox. All Right Reserved</h5>
        </div>
      </Container>
    </div>
  );
}

export default Footer;