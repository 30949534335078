import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Slider from 'react-slick';


function PhilosofyP() {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };
  return (
    <div className="padd-space padd-lg-top">
      <Container>
        <h1 className="dflex-al-center"><span className="point-des yello">.</span>Philosofy</h1>
        <br />
        <Slider {...settings}>
          <Card body className="App padd-space padd-sides">
              <span className="phylo grey-text">Mision</span>
            <br />
              <span className="phylo"><span className="red-text">Create</span> a <span className="blue-text">better </span> <span className="green-text">future</span> for <span className="yello-text">humankind</span>.</span>
          </Card>
          <Card body className="App padd-space padd-sides-lg ">
              <span className="phylo grey-text">Vision</span>
            <br />
              <span className="phylo">Our <span className="yello-text">companies</span> and <span className="yello-text">products</span> are design with <span className="green-text">data science</span>, state of the <span className="blue-text">art technology</span> and <span className="red-text">customer</span> centric <span className="red-text">experiences</span>.</span>
          </Card>
        </Slider>
        <br />
        <br />

        <h1 className="dflex-al-center"><span className="point-des blue">.</span>Corporate Management Values</h1>
        <Row>
          <Col lg={3} md={6} xs={12}>
            <Card >
              <Card.Body>
                <Card.Title> <h4 className="blue-text">Service to the public</h4> </Card.Title>
                <Card.Text className="mb-2 text-muted">
                  <p>By providing high-quality goods and services at reasonable prices, we contribute to the public’s well-being.</p>
                  <p>We will always be cordial and modest and respect the rights and needs of others.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={3} md={6} xs={12}>
            <Card >
              <Card.Body>
                <Card.Title> <h4 className="red-text">Fairness and honesty</h4> </Card.Title>
                <Card.Text className="mb-2 text-muted">
                  <p>We will be fair and honest in all our business dealings and personal conduct.</p>
                  <p>We will always be grateful for all the blessings and kindness we have received. </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={3} md={6} xs={12}>
            <Card >
              <Card.Body>
                <Card.Title> <h4 className="green-text">Teamwork for the common cause</h4> </Card.Title>
                <Card.Text className="mb-2 text-muted">
                  <p>We will pool abilities, based on mutual trust and respect.</p>
                  <p>The quest to transform companies is a dangerous, painful and slow.</p>
                  <p>We will constantly strive to improve our corporate and personal performances.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={3} md={6} xs={12}>
            <Card >
              <Card.Body>
                <Card.Title> <h4 className="yello-text">Uniting effort for improvement</h4> </Card.Title>
                <Card.Text className="mb-2 text-muted">
                  <p>We will constantly strive to improve our corporate and personal performances.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={3} md={6} xs={12}>
            <Card >
              <Card.Body>
                <Card.Title> <h4 className="blue-text">Courtesy and humility</h4> </Card.Title>
                <Card.Text className="mb-2 text-muted">
                  <p>We will always be cordial and modest and respect the rights and needs of others.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={3} md={6} xs={12}>
            <Card >
              <Card.Body>
                <Card.Title> <h4 className="red-text">Accordance with natural laws</h4> </Card.Title>
                <Card.Text className="mb-2 text-muted">
                  <p>We will abide by the laws of nature and adjust to the ever-changing conditions around us.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={3} md={6} xs={12}>
            <Card >
              <Card.Body>
                <Card.Title> <h4 className="green-text">Gratitude for blessing</h4> </Card.Title>
                <Card.Text className="mb-2 text-muted">
                  <p>We will always be grateful for all the blessings and kindness we have received.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>          
        </Row>

        <div><h1 className="dflex-al-center"><span className="point-des green">.</span>The Way We Work</h1></div>
        <Row>
          <Col lg={3} md={6} xs={12}>
            <Card >
              <Card.Body>
                <Card.Title> <h4 className="blue-text">Kodawari</h4> </Card.Title>
                <Card.Text className="mb-2 text-muted">
                  <p>The uncompromising and relentless pursuit of perfection.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={3} md={6} xs={12}>
            <Card >
              <Card.Body>
                <Card.Title> <h4 className="red-text">Kaizen</h4> </Card.Title>
                <Card.Text className="mb-2 text-muted">
                  <p>Japanese business philosophy of continuous improvement of working practices and personal efficiency.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={3} md={6} xs={12}>
            <Card >
              <Card.Body>
                <Card.Title> <h4 className="yello-text">Horenso</h4> </Card.Title>
                <Card.Text className="mb-2 text-muted">
                  <p>Frequent reporting, touching base and discussing – important attributes that are said to characterize collaboration and information flow within effective Japanese corporate culture.</p>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}


export default PhilosofyP;