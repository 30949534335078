import React, {useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button';

import { collection, getDocs, addDoc } from 'firebase/firestore';
import db from '../../../lib/firebaseConfig'


function FormArticle() {

  const [ postData, setPostData ] = useState({})

  const changesHandler = event => {
    const value = event.target.value
    const property = event.target.name

    setPostData({...postData, [property]: value})
    console.log(property, value)
    console.log(postData)
  }
 
  const savePost = () => {
    console.log( postData )
  }

  useEffect(() => {
//    const saveData = async() => {
//      try {
//        const docRef = await addDoc(collection(db, "posts"), postData);
//        console.log("Document written with ID: ", docRef.id);
//      } catch (e) {
//        console.error("Error adding document: ", e);
//      }
//    }
//
    
  }, [])

  return (

  <div className="padd-space padd-lg-top">
    <Container>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label >Titulo</Form.Label>
          <Form.Control
            id="title"
            type="text"
            name="title"
            onChange={changesHandler}
          />

          <Form.Label >Autor</Form.Label>
          <Form.Control
            id="autor"
            type="text"
            name="autor"
            onChange={changesHandler}
          />
        </Form.Group>

        <Form.Group className="mb-3" >
          <Form.Label >Content</Form.Label>
          <Form.Control
            id="content"
            name="content"
            placeholder="Leave a comment here"
            as="textarea"
            style={{ height: '100px' }}
            onChange={changesHandler}
          />
        </Form.Group>

        <Form.Group className="position-relative mb-3">
          <Form.Label >File</Form.Label>
          <Form.Control id="image" type="text" name="image" onChange={changesHandler}/>
        </Form.Group>
  
        <Button className="mt-3" type="submit" onClick={savePost}>Submit form</Button>
      </Form>
    </Container>
  </div>
  );
}

export default FormArticle;