
import React from 'react';
import Container from 'react-bootstrap/Container';


function AntiCorruptionPolicy() {
  return (
    <div className="padd-space padd-lg-top">
    <Container>

      <h1 className="dflex-al-center"><span className="point-des yello">.</span>Política Anticorrupción</h1>
        <br />
      <div>
        <h2>ÍNDICE</h2>
        <ul>
          <li>PROPÓSITO</li>
          <li>DEFINICIONES  </li>
          <li>ACTIVIDADES PROHIBIDAS </li>
          <li>REGALOS, COMIDAS, VIAJES Y ENTRETENIMIENTO </li>
          <li>PROHIBICIONES </li>
          <li>PRÁCTICAS ACEPTABLES</li>
          <li>VIAJES </li>
          <li>ENTRETENIMIENTO</li>
          <li>DONATIVOS </li>
          <li>SOCIOS COMERCIALES</li>
          <li>INFORMACIÓN Y NO REPRESALIAS</li>
          <li>MEDIDAS DISCIPLINARIAS</li> 
        </ul>
      </div>

      <h4>I. PROPÓSITO</h4>
      <p>
      AESIR CORPORATION, sus subsidiarias y filiales, a través de la presente política se compromete en atender el cumplimiento de los requerimientos legales anticorrupción, mediante la adopción de una filosofía basada en realizar negocios   con altos estándares de honestidad, integridad, honradez y responsabilidad, como principios fundamentales que evidencian el compromiso de actuar apegados a la integridad empresarial y trasladándola a todos los miembros de la compañía. 
      </p>
      <p>
      Mediante este actuar AESIR CORPORATION asume un compromiso público con la gestión y el desempeño responsable mediante un actuar ético y transparente ante sus grupos de interés y la conducción de sus negocios de una manera socialmente responsable, actuando con una filosofía de Cero Tolerancia a actos que contraríen sus principios organizacionales.
      </p>
      <br />

      <h4>II. ALCANCE </h4>
      <p>
      Esta Política es aplicable a todos los colaboradores de AESIR CORPORATION, así como a todas las partes relacionadas y grupos de interés, entendidos estos como: clientes, proveedores, accionistas, inversionistas, aliados, empleados, contratistas, subcontratistas; y en general a todos aquellos con quienes de manera directa o indirectamente se establezca alguna relación comercial, contractual, laboral o de cooperación.
      </p>
      <br />

      <h4>III. DEFINICIONES</h4>
      <p>
        <strong>Corrupción:</strong> Es todo hecho, tentativa u omisión deliberada para obtener un beneficio para sí o para terceros en detrimento de los principios organizacionales, independiente de los efectos financieros sobre las empresas. 
      </p>
      <p>
      <strong>Soborno:</strong> Consiste en ofrecer, prometer, dar, aceptar o solicitar una contraprestación, económica o de con cualquier otro tipo de cosa de valor, con el propósito de dirigir la conducta de alguien para obtener ventaja de índole comercial, contractual, reglamentaria o personal, para motivar a alguien a abstenerse de realizar un acto relacionado a sus funciones o para que abusen de su influencia.
      </p>
      <p>
      El soborno y la corrupción son delitos penales que afectan tanto a quienes participan en forma activa como pasiva en la comisión de estos hechos, así como a las organizaciones a quienes representan.
      </p>
      <p>
      <strong>Cosas de valor:</strong>  Es un concepto amplio y puede incluir dinero en efectivo, equivalentes de efectivo (por ejemplo, tarjetas de regalo), comidas, entretenimiento, viajes, obsequios, empleo, contratos, servicios en especie, o cualquier otro tipo de bienes o servicios con valor económico tangible, contribuciones a fines benéficos o a otras organizaciones sin ánimo de lucro y patrocinios promocionales.
      </p>
      <p>
      <strong>Servidor/Funcionario público:</strong> Cualquier empleado de un órgano de gobierno, electo o designado como integrante de los Poderes Ejecutivo, Legislativo y Judicial, órganos constitucionales autónomos, empresas en las que tenga participación el Estado o agencias de investigación, perteneciente a cualquier nivel de gobierno (federal, nacional, estatal, provincial o municipal) o cualquier partido político, funcionario de un partido político o candidatos para cualquier puesto de elección popular.
      </p>
      <p>
      <strong>Socio Comercial:</strong> Terceros actuando en nombre o por cuenta de la Compañía o de cualquier entidad propiedad de, o controlada por la Compañía, que puedan interactuar con agentes externos, especialmente en el caso en que estos sean Servidores/Funcionarios Públicos; o cualquier alianza en la que la Compañía tenga intereses económicos, en los que intervengan, entre otros, a modo de ejemplo: agentes, brokers, intermediarios, asesores, consultores, representantes, socios de jointventures, co-inversores, franquiciados, proveedores autorizados, agencias de viaje, transportistas autorizados o agentes de aduanas y/o abogados actuando en nombre o por cuenta de la Compañía.
      </p>
      <p>
      <strong>Colaborador:</strong> Cualquier persona vinculada laboralmente con la Compañía a través de un contrato de tiempo determinado o indeterminado.
      </p>
      <br />

      <h4>IV. ACTIVIDADES PROHIBIDAS</h4>
      <p>
        Cuando de corrupción se trata, la Compañía no tendrá ningún distingo entre servidores públicos y particulares: El soborno y la corrupción no son tolerados sin importar los involucrados. 
        <ul>
          <li>Ofrecimiento de beneficios indebidos.</li>
        </ul>
      </p>
      <p>
        Los colaboradores de AESIR CORPORATION no deben (directamente o a través terceros) ofrecer o realizar sobornos a servidores públicos o particulares (ni a los familiares de éstos ni a terceros) con la intención de inducirlos a usar su posición o poder para ayudar a la Empresa a obtener una ventaja indebida.
        <ul>
          <li>Recepción de beneficios indebidos. </li>
        </ul>
      </p>
      <p>
      Los colaboradores no deben solicitar o aceptar (o intentar realizar dichas conductas), ya sea de manera directa o indirecta, a través de algún familiar o tercero, ninguna cosa de valor de un servidor público o un particular por el que, a cambio, pudiera dar como resultado una ventaja aparente e indebida. Queda claro, que para la Compañía no existe cantidad aceptable para un soborno.
      </p>
      <br />

      <h4>V. REGALOS, COMIDAS, VIAJES Y ENTRETENIMIENTO.</h4>
      <p>V.I.- Prohibiciones</p>
      <br />
      <p>
      Los colaboradores nunca deben: i) Ofrecer regalos, comidas, viajes o entretenimiento a servidores públicos o a particulares (o sus familiares o terceros) con la intención de influir o inducir al receptor a hacer cualquier cosa que ayude a la Empresa a asegurar o mantener cualquier beneficio o ventaja indebidos. Ii) Pedir, recibir o aceptar regalos, comidas, viajes o entretenimiento a servidores públicos o a particulares (o sus familiares y terceros), sabiendo o suponiendo que, a cambio, dichas personas esperan recibir cualquier beneficio o ventaja indebidos.
      </p>
      <br />

      <p>V.II.- Prácticas aceptables</p>
      <br />
      <p>
      Los regalos e invitaciones de negocios nunca deben ser ofrecidos o aceptados con propósitos ilícitos y deben ser, en todos los casos: i) Conformes a las leyes y regulaciones, tanto en el país del que realiza la invitación, como del que la recibe, ii) Entregados o aceptados sin espera de reciprocidad: la intención o propósito que persigue el regalo o invitación debe ser simplemente la de construir la relación de negocio dentro de unos estándares normales de cortesía y no la de influir en la persona encargada de adoptar una determinada decisión de negocio, iii) Aceptables socialmente: otras personas (tales como compañeros, competidores o prensa) deberían estar de acuerdo en que el regalo o invitación es razonable y que se podría comunicar sin temor a un posible reproche social; iv) Consistentes con los intereses de los negocios de la Compañía, así como con las prácticas habituales del negocio, v) Ocasionales y no excesivos, conforme a los estándares locales o de la industria, vi) Registrados en documentación precisa, apropiada y con detalle razonable. Se deberá tener en cuenta que los clientes o proveedores de la Compañía pueden tener sus propias normas internas sobre esta materia y que es posible que no coincidan con los estándares establecidos en esta norma. En consecuencia, se deberá considerar esta circunstancia a la hora de ofrecer regalos o invitaciones a consejeros, directivos y empleados de estas empresas con el fin de evitar que su propia reputación o la de su empresa puedan verse comprometidas.
      </p>
      <p>
      No se considerará irregular la aceptación por parte de los consejeros, directivos y empleados de la Compañía, dentro de los usos sociales empresariales, de invitaciones a eventos promocionales o de negocio, en los que la entidad o la empresa organizadora (distinta de cualquier compañía de AESIR CORPORATION) asuma los gastos razonables de desplazamiento, alojamiento y/o manutención, siempre que el objeto de dicha invitación sea exclusivamente la presentación de productos o servicios de dicha entidad o empresa organizadora y la invitación no sea individual, sino dirigida a un colectivo de clientes o potenciales clientes.
      </p>
      <br />

      <p>V.III. - Viajes.</p>
      <br />
      <p>
        Por ningún motivo un colaborador pagará vacaciones ni tours de ningún tipo a particulares, incluyendo familiares y amigos de éstos; sólo podrá invitar un viaje a un particular, incluyendo transporte aéreo, hospedaje y transportación local, siempre y cuando: 
        <ul>
          <li>Sea para un fin justificado de negocios, como demostrar productos o servicios, capacitaciones y conferencias, o para inspecciones de instalaciones u operaciones de la Empresa.</li>
          <li>Los gastos deben ser conforme a lo necesario y no deben existir gastos irrelevantes o extravagante.</li>
          <li>En el viaje no deben incluirse amigos ni familiares del invitado;</li>
          <li>El viaje no implique desviarse o aprovechar la visita a lugares no planeados (estos desvíos son innecesarios cuando requieren extender el viaje original a otros lugares o impliquen realizar gastos innecesarios o injustificables).</li>
          <li>La invitación al viaje no incluye entregar efectivo como viático para gastar por día.</li>
        </ul>
      </p>
      <br />

      <p>V.IV.- Entretenimiento. </p>
      <br />
      <p>
        Un colaborador puede obsequiar boletos para eventos de entretenimiento a un particular, siempre que:
        <ul>
          <li>El colaborador esté presente durante el evento; </li>
          <li>El valor del boleto del evento no exceda a lo irracional o extravagante.</li>
          <li>El lugar no sea inapropiado, y</li>
          <li>La finalidad del evento sea para promover, demostrar o explicar los productos o servicios de la Empresa.</li>
        </ul>
      </p>
      <p>
      Un colaborador puede aceptar boletos para eventos de entretenimiento de un particular, siempre y cuando al evento asista la persona que le hizo la invitación. Cualquier evento de entretenimiento que reciba un colaborador debe ser reportado a la Dirección de la empresa.
      </p>
      <br />

      <p>V.V.- Donativos </p>
      <br />
      <p>
        La Empresa no usará donativos como un medio para encubrir actos de corrupción o soborno.
      </p>
      <br />

      <h4>VI. SOCIOS COMERCIALES </h4>
      <br />
      <p>
      La Compañía dispone de diversas normas que establecen disposiciones obligatorias sobre las relaciones con proveedores y otros Socios Comerciales. La Compañía no contratará o realizará negocios con un Socio Comercial si cree que hay riesgo de que éste vaya a violar las leyes aplicables en materia de anticorrupción o las prohibiciones establecidas en esta Política. 
      </p>
      <p>
      Antes de establecer una relación de negocios con cualquier Socio Comercial que vaya a interactuar en nombre de la Compañía, con especial énfasis en caso de interactuar con Funcionarios/Empleado Públicos, el área de Compras (en el caso de negociaciones dentro de su ámbito de actuación) o el área de Clientes (en contrataciones fuera del ámbito del área de Compras), deberán realizar una valoración apropiada del Socio Comercial a este respecto y se asegurarán de que: (a) el contrato con el Socio Comercial contiene obligaciones y garantías anticorrupción, así como el derecho de  de dar por terminado el contrato en caso de incumplimiento de dichas obligaciones y garantías; (b) el Socio Comercial certifica el cumplimiento de las leyes en materia de anticorrupción.
      </p>
      <br />

      <h4>VII. INFORMACIÓN Y NO REPRESALIAS </h4>
      <br />
      <p>
      Todos los colaboradores y/o empleados de AESIR CORPORATION, así como socios comerciales y proveedores que tengan conocimiento o sospecha de que esta política pueda haber sido violada deberán notificar a la Dirección de Recursos Humanos y/o a la Gerencia Legal de forma inmediata.
      </p>
      <p>
      La empresa no tolerará represalias contra las personas que informen de buena fe. Toda persona que tenga conocimiento de lo que cree ser una forma de represalia debe informar a la Dirección de Recursos Humanos y/o a la Gerencia Legal.
      </p>
      <br />

      <h4>VIII. MEDIDAS DISCIPLINARIAS</h4>
      <br />
      <p>
      Los empleados de AESIR CORPORATION que violen esta política estarán sujetos a medidas disciplinarias, que pueden incluir el despido y también pueden ser objeto de enjuiciamiento penal individual y / o enjuiciamiento civil en las jurisdicciones pertinentes. Así mismo, los socios empresariales y/o colaboradores que violen esta política están sujetos a la terminación de todas las relaciones comerciales con AESIR CORPORATION, sin perjuicio de las acciones penales o civiles que puedan ejercerse.
      </p>

    </Container>
    </div>
    );
  }
  
export default AntiCorruptionPolicy;