import '../../../App.css';
import './blog.css';
import React from 'react';
import { Card } from 'react-bootstrap';
import Slider from "react-slick";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function SliderBlog() {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      }
    ]
  };
  return (
    <div className="padd-md-bottom ">
      <Slider {...settings}>
        <div className="slide-blog slide-1">
    
          <div className="grid-1">
            <a href="https://blog.hubspot.es/marketing/que-es-seo-y-como-mejorar-tu-posicionamiento-en-buscadores" target="_blank" rel="noopener noreferrer">
            <Card className="  text-white">
              <Card.Img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359019/aesir/Blog%20Aesir/SEO_01_olvrmt.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Subtitle className="mb-2 text-muted">SEO Y CEM</Card.Subtitle>
                <Card.Title>Qué es SEO y cómo mejorar tu posición en buscadores</Card.Title>
              </Card.ImgOverlay>
            </Card>
            </a>
          </div>

          <div className="grid-2 only-desktop">
            <a href="https://blog.hubspot.es/marketing/como-aprender-seo-en-30-dias" target="_blank" rel="noopener noreferrer">
            <Card className="  text-white">
              <Card.Img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359019/aesir/Blog%20Aesir/SEO_02_vvs5zz.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Subtitle className="mb-2 text-muted">SEO Y CEM</Card.Subtitle>
                <Card.Title>Cómo aprender SEO en 30 días</Card.Title>
              </Card.ImgOverlay>
            </Card>
            </a>
            <a href=" https://blog.hubspot.com/service/customer-journey-map" target="_blank" rel="noopener noreferrer">
            <Card className="  text-white">
              <Card.Img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359018/aesir/Blog%20Aesir/CRM_01_dndehi.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Subtitle className="mb-2 text-muted">CRM</Card.Subtitle>
                <Card.Title>How to Create an Effective Customer Journey Map [Examples + Template]</Card.Title>
              </Card.ImgOverlay>
            </Card>
            </a>
            <a href="https://blog.hubspot.com/service/how-to-calculate-customer-lifetime-value" target="_blank" rel="noopener noreferrer">
            <Card className="  text-white">
              <Card.Img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359018/aesir/Blog%20Aesir/CRM_02_spssb6.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Subtitle className="mb-2 text-muted">CRM</Card.Subtitle>
                <Card.Title>How to Calculate Customer Lifetime Value</Card.Title>
              </Card.ImgOverlay>
            </Card>
            </a>
            <a href="https://blog.hubspot.com/service/customer-retention-metrics" target="_blank" rel="noopener noreferrer">
            <Card className="  text-white">
              <Card.Img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359019/aesir/Blog%20Aesir/CRM_03_nwmvlj.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Subtitle className="mb-2 text-muted">CRM</Card.Subtitle>
                <Card.Title>10 Customer Retention Metrics & How to Measure Them</Card.Title>
              </Card.ImgOverlay>
            </Card>
            </a>
          </div>

        </div>
        <div className="slide-blog slide-2">
  
          <div className="grid-1">
            <a href="https://monday.com/blog/news/a-letter-from-our-founders/" target="_blank" rel="noopener noreferrer">
            <Card className="  text-white">
              <Card.Img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633375016/aesir/Blog%20Aesir/FINANCE_03_oywoyz.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Subtitle className="mb-2 text-muted">FINANCE</Card.Subtitle>
                <Card.Title>A letter from our founders: monday.com is now a public company!</Card.Title>
              </Card.ImgOverlay>
              </Card>
            </a>
          </div>

          <div className="grid-2 only-desktop">
            <a href="https://www.salesforce.com/mx/blog/2021/09/preparacion-para-una-transformacion.html" target="_blank" rel="noopener noreferrer">
            <Card className="  text-white">
              <Card.Img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633375016/aesir/Blog%20Aesir/COMPANIES_09_kcukru.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Subtitle className="mb-2 text-muted">COMPANIES</Card.Subtitle>
                <Card.Title>Cómo los directores de Informática de Salesforce utilizan Salesforce para impulsar el cambio y respaldar las operaciones</Card.Title>
              </Card.ImgOverlay>
            </Card>
            </a>
            <a href="https://www.merca20.com/everything-you-need-to-know-about-seo-in-5-minutes/" target="_blank" rel="noopener noreferrer">
            <Card className="  text-white">
              <Card.Img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359019/aesir/Blog%20Aesir/SEO_02_vvs5zz.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Subtitle className="mb-2 text-muted">SEO Y SEM</Card.Subtitle>
                <Card.Title>Everything You Need to Know About SEO (in 5 Minutes)</Card.Title>
              </Card.ImgOverlay>
            </Card>
            </a>
            <a href="https://slack.com/intl/es-mx/blog/news/slack-innovations-unveiled-dreamforce" target="_blank" rel="noopener noreferrer">
            <Card className="  text-white">
              <Card.Img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633375016/aesir/Blog%20Aesir/CRM_04_cfbbcb.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Subtitle className="mb-2 text-muted">CRM</Card.Subtitle>
                <Card.Title>Nuevas innovaciones de productos de Slack presentadas en Dreamforce</Card.Title>
              </Card.ImgOverlay>
            </Card>
            </a>
            <a href="https://blogs.oracle.com/oracle-latinoamerica/post/oracle-cloud-erp-gartner-cuadrante" target="_blank" rel="noopener noreferrer">
            <Card className="  text-white">
              <Card.Img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633375016/aesir/Blog%20Aesir/ERP_04_ewudac.png" alt="Card image" />
              <Card.ImgOverlay>
                <Card.Subtitle className="mb-2 text-muted">ERP</Card.Subtitle>
                <Card.Title>Oracle Cloud ERP: La opción ideal para las organizaciones centradas en el producto</Card.Title>
              </Card.ImgOverlay>
            </Card>
            </a>
          </div>

        </div>
      </Slider>
    </div>
  );
}

export default SliderBlog;