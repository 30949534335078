
import React from 'react';
import FormArticle from './blog-components/form-article';

function CreateArticle() {
  return (
    <div>
      <FormArticle />
    </div>
  );
}

export default CreateArticle;