
import React from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table'


function InformationSecurityPolicies() {
  return (
    <div className="padd-space padd-lg-top">
    <Container>

        <h1 className="dflex-al-center"><span className="point-des yello">.</span>Políticas De Seguridad De La Información</h1>
        <br />
        <div>
          <h2>ÍNDICE</h2>
          <ul>
            <li>PROPÓSITO</li>
            <li>ALCANCE</li>
            <li>DEFINICIÓN DE LA POLÍTICA</li>
            <li>PUNTOS CLAVE DE LA POLÍTICA</li>
            <li>REVISIÓN DE LA POLÍTICA</li>
            <li>SEGURIDAD DE LA INFORMACIÓN</li>
            <li>POLÍTICA DE DISPOSITIVOS MÓVILES Y ACCESO REMOTO </li>
            <li>POLÍTICA DE SEGURIDAD EN RECURSOS HUMANOS</li>
            <li>POLÍTICA DE GESTIÓN DE LOS ACTIVOS</li>
            <li>POLÍTICA DE SEGURIDAD FÍSICA Y AMBIENTAL</li>
            <li>POLÍTICA DE SEGURIDAD DE LOS EQUIPOS</li>
            <li>POLÍTICA DE SEGURIDAD EN LAS OPERACIONES</li>
            <li>POLÍTICA DE GESTIÓN DE INCIDENTES DE SEGURIDAD  </li>
            <li>POLÍTICA DE CIFRADO</li>
          </ul>
        </div>

        <h4>1. PROPÓSITO</h4>
        <br />
        <p>
          El presente documento tiene como objetivo establecer las políticas generales de seguridad de la información de AESIR CORPORATION, mismas que deberán ser cumplidas siempre buscando la preservación de la confidencialidad, integridad y disponibilidad de la información de la empresa.
        </p>
        <br />

        <h4>2. ALCANCE</h4>
        <br />
        <p>
          Las políticas contenidas en este documento aplican para  todo el personal que labore o preste servicios en la empresa, así como, para las personas que utilicen las tecnologías de la información y comunicación de la empresa.
        </p>
        <br />

        <h4>3. DEFINICIÓN DE LA POLÍTICA</h4>
        <br />
        <p>
          AESIR CORPORATION reconoce que la información interna, los activos de información y la información proporcionada por sus clientes es de suma importancia para la operación de la empresa, por lo que a través de esta política se compromete a protegerlos, estableciendo mecanismos para restringir el acceso, uso y revelación, conforme a sus intereses institucionales.
        </p>
        <br />

        <h4>4. PUNTOS CLAVE DE LA POLÍTICA</h4>
        <br />
        <p>
          <ul>
            <li>Recalcar el compromiso de la empresa en relación a la seguridad de la información.</li>
            <li>Crear una cultura de seguridad internamente, en relación con todo el personal, como externamente, en relación con los clientes y proveedores de la empresa.</li>
            <li>Definir, desarrollar e implementar los controles técnicos y organizativos que resulten necesarios para garantizar la confidencialidad, integridad y disponibilidad</li>
            <li>Considerar la seguridad de la información como un proceso de mejora continua que permita mejorar los niveles de seguridad internos y externos.</li>
          </ul>
        </p>
        <br />

        <h4>5. REVISIÓN DE LA POLÍTICA</h4>
        <br />
        <p>
          En el presente documento se establecen las políticas generales de seguridad de la información, que tendrán efecto inmediato a partir de la fecha de su autorización y publicación, su revisión se hará de manera anual para cumplir las necesidades  de la Institución en materia de seguridad de la información.
          <br />
          Es fundamental su difusión entre todos los colaboradores de la empresa, para su conocimiento.
        </p>
        <br />

        <h4>6. SEGURIDAD DE LA INFORMACIÓN EN LA GESTIÓN DE PROYECTOS</h4>
        <br />
        <p>
        Se implementarán los estándares funcionales, operativos y tecnológicos, que deben incorporarse en el desarrollo de proyectos, adquisición de servicios y componentes de tecnologías de información y comunicación.
        </p>
        <br />
        
        <h4>7. POLÍTICA DE DISPOSITIVOS MÓVILES Y ACCESO REMOTO</h4>
        <br />
        <p>
        El personal será responsable de proteger los equipos que la empresa le hay proporcionado para desempeñar las funciones de su cargo, comprometiéndose a no exponer al equipo a consideraciones de inseguridad física y/o ambiental, a proteger las claves de acceso que le han sido proporcionadas y a no dejar el equipo desatendido en lugares públicos o en lugares donde su su sustracción y daño pueda ser de fácil acceso para otros.
        <br />
        El área de IT de AESIR CORPORATION se asegurará de que todos los dispositivos cuenten con el software adecuado para preservar la información de la empresa, incluirán de forma enunciativa más no limitativa: antivirus, software de cifrado, aplicaciones seguras, etc.
        </p>
        <br />
        
        <h4>8. POLÍTICA DE SEGURIDAD EN RECURSOS HUMANOS</h4>
        <br />
        <p>
        El objetivo de la presente es definir las políticas que aseguren una adecuada protección de la información de AESIR CORPORATION por parte del personal interno y externo.
        <br />
        La Dirección General, atreves del Departamento de Recursos Humanos se reserva el derecho de utilizar los medios a su alcance para investigar posibles violaciones a este reglamento, siempre que sea respetada la confidencialidad de la información. Así mismo se reserva el derecho de suspender o eliminar el acceso a cualquier equipo computacional a cualquier usuario, sin previo aviso al mismo, si el hacerlo es necesario para mantener la disponibilidad, seguridad e integridad de las operaciones para los demás usuarios de AESIR CORPORATION o cuando se presuma alguna falta o violación a este reglamento u otros pertinentes que amerita este tipo de acciones para el proceso de investigación.
        <br />
        AESIR CORPORATION, cumple con la responsabilidad de establecer y aprobar políticas mismas que serán del cumplimiento obligatorio de todos los colaboradores que presten sus servicios mediante un contrato de trabajo; conozca, acepte y se comprometa a seguir los lineamientos de operación en este documento anexo al Reglamento Interno de Trabajo, en caso contrario podrá ser acreedor a actas administrativas, económicas o baja definitiva.
        </p>
        <br />
        
        <h4>8.1 PROCESO DE RECLUTAMIENTO</h4>
        <br />
        <p>
        La selección del personal será responsabilidad del área de Recursos Humanos, quien realizará la evaluación integral del personal asegurando que el perfil cumpla con los requisitos de cada puesto.
        <br />
        El área de RH deberá  de hacer del conocimiento del personal de la empresa, las cláusulas de confidencialidad, en caso del personal subcontratado, los contratos deberán de incluir una cláusula de confidencialidad de la información y de no divulgación.
        </p>
        <br />
        
        <h4>8.2. RESPONSABILIDADES DE LAS DIRECCIONES</h4>
        <br />
        <p>
        La dirección de recursos humanos es responsable de informar a todo el personal de nuevo ingreso, de la existencia de estas políticas.
        <br />
        Los mandos medios y superiores de las áreas deben de promover y hacer del conocimiento a todo el personal de su cargo de estas políticas generales de la seguridad de la información.
        </p>
        <br />
        
        <h4>8.3. CONCIENTIZACIÓN Y FORMACIÓN EN SEGURIDAD DE LA INFORMACIÓN</h4>
        <br />
        <p>
        Las direcciones de cada área deberán de promover en todo momento la participación en los procesos de concientización y capacitación, además de prevención de incidentes de seguridad, a todo el personal a su cargo, para garantizar la seguridad de la información.
        </p>
        <br />
        
        <h4>8.4. PROCESO DISCIPLINARIO.</h4>
        <br />
        <p>
        En caso de cualquier incidente u omisión, el personal de AESIR CORPORATION deberá reportar la falta al área de Recursos Humanos y Legal, igualmente al correo de <a href="mail:legal@aesir.com.mx">legal@aesir.com.mx</a> , para que se analice la falta y su nivel de gravedad y se resuelva lo conducente.
        </p>
        <br />
        
        <h4>8.5. RESPONSABILIDADES DE LA TERMINACIÓN O SEPARACIÓN DEL PUESTO</h4>
        <br />
        <p>
        Toda terminación o separación del puesto laboral cumplirá con los procesos del área de recursos humanos, se hará de manera ordenada, se comunicará a los demás colaboradores en la empresa la finalización de los labores de dicho empleado, así como el cambio de puesto o de funciones del mismo. Además, se deberá de comunicar la inhabilitación del usuario, que por las reglas de negocio tenga funciones específicas consideradas, para disminuir riesgos en la operación de la empresa.
        </p>
        <br />
        
        <h4>9. GESTIÓN DE ACTIVOS</h4>
        <br />
        <p>
        La presente tiene como objeto asegurar la protección de la información institucional y de los activos de información que contengan.
        <br />
        Se considera un activo de información, aquel que almacena datos, registros, información en cualquier medio y que se considera valioso para AESIR CORPORATION, que se considera irremplazable e irrepetible. Cada área y sus superiores deben de identificar los activos de información que les corresponden.
        <br />
        La información es un activo importante que AESIR CORPORATION tiene la responsabilidad y el requisito de proteger, por lo que la protección de los activos de información no se limita simplemente a cubrir las existencias de información (datos electrónicos o registros en papel) de la empresa. También se dirige a las personas que los usan, los procesos que siguen y el equipo informático físico utilizado para acceder a ellos.
        <br />
        Toda la información que sea generada a partir de un activo propio, arrendado o contratado por un servicio, es propiedad de AESIR CORPORATION, debe ser asignado cada uno a un responsable y autorizado por su jefe inmediato.  La persona responsable del activo debe salvaguardar su integridad, disponibilidad y confidencialidad del activo, hace uso del mismo unicamente para los propsitos y actividades que se deriven de su puesto de la empresa, reportar cualquier incidente o problema relacionado con el activo de información, cualquier omisión de reportar algún incidente relacionado con la información o cualquier activo bajo su custodia, será considerado una falta hacia la seguridad de la información que deberá ser reportada a las autoridades competentes.
        <br />
        Los recursos empleados para el procesamiento de la información son prioritarios para el desarrollo de los procesos internos de AESIR CORPORATION y para que pueda seguir cumpliendo con sus funciones, por lo anterior, el personal es responsable de salvaguardar de cualquier modificación no autorizada, daño o destrucción que límite su disponibilidad para el adecuado desarrollo de sus actividades.
        </p>
        <br />
        
        <h4>9.1. DEVOLUCIÓN DE ACTIVOS</h4>
        <br />
        <p>
        Todo el personal de AESIR CORPORATION, cuando llegue el momento de concluir sus funciones, tiene la obligación de entregar los activos informáticos asignados en buen estado físico y de operación, así como los activos de información y la documentación correspondiente que se le haya entregado desde el primer día en que empezó a laborar en la empresa.
        </p>
        <br />
        
        <h4>9.2. CLASIFICACIÓN DE LA INFORMACIÓN</h4>
        <br />
        <p>
        AESIR CORPORATION será responsable de asignar clasificaciones a la información como conjuntos, de acuerdo con el sistema de clasificación de información estándar que se presenta a continuación; igualmente, siempre que sea posible, la categoría de información se integrará en la información misma.
        <br />
        Se entenderá por información confidencial que no es del dominio público y que incluye de manera enunciativa más no limitativa, toda información tangible o intangible, ya sea de manera oral o escrita, electrónica, grabada, filmada o por cualquier otro medio, o contenida en cualquier tipo de documento, archivo, o cualquier medio de almacenamiento que pueda guardar información independientemente de su naturaleza, y que puede corresponder a productos, datos personales de personas relacionadas con los colaboradores de la empresa, sean personal, proveedores, clientes y visitantes, precios, planes de negocio, ofertas técnicas y económicas para licitaciones públicas  o privadas, comercialización o promoción de cualquier producto, prácticas y/o políticas comerciales, programas de sistemas, software, información organizacional de clientes, de cartera, base de datos, contactos comerciales, socios actuales y potenciales, servicios prestados, reportes, planes, proyecciones de mercado, fórmulas, mecanismos, patrones, métodos, técnicas, procesos de análisis; documentos de trabajo, compilaciones, comparaciones, estrategias, estudios y en general toda aquella información marcada como confidencial y documentación relacionada y referida a la actividad comercial y de negocios de AESIR CORPORATION.
        </p>
        <br />

        <div>
          <Table responsive bordered hover size="sm">
            <thead>
              <tr>
                <th>Categoría de información</th>
                <th>Descripción</th>
                <th>Ejemplos</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Público sin clasificar </td>
                <td>La información no es confidencial y puede hacerse pública sin ninguna implicación para la compañía. La pérdida de disponibilidad debido al tiempo de inactividad del sistema es un riesgo aceptable. La integridad es importante, pero no vital.</td>
                <td>
                  <ul>
                    <li>Folletos de productos ampliamente distribuidos</li>
                    <li>Información ampliamente disponible en el dominio público, incluidas las áreas del sitio web de la compañía disponibles públicamente.</li>
                    <li>Descargas de muestra del software de la compañía que está a la venta.</li>
                    <li>Informes financieros requeridos por las autoridades reguladoras.</li>
                    <li>Boletines para transmisión externa</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Propiedad</td>
                <td>La información se limita a la administración aprobada de acceso interno y protegido del acceso externo. El acceso no autorizado podría influir en la efectividad operativa de la compañía, causar una pérdida financiera importante, proporcionar una ganancia significativa a un competidor, o causar una caída importante en la confianza del cliente. La integridad de la información es vital.</td>
                <td>
                  <ul>
                    <li>Contraseñas e información sobre procedimientos de seguridad corporativa.</li>
                    <li>Conocimientos utilizados para procesar la información del cliente</li>
                    <li>Procedimientos operativos estándar utilizados en todas las partes del negocio de la compañía</li>
                    <li>Todo el código de software desarrollado por la compañía, ya sea ​​que se use internamente o se venda a clientes</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  Cliente Datos 
                  <br /> 
                  Confidenciales
                </td>
                <td>Información recibida de los clientes en cualquier forma para su procesamiento en producción por la compañía. La copia original de dicha información no debe modificarse de ninguna manera sin un permiso por escrito del cliente. Los niveles más altos posibles de integridad, confidencialidad y disponibilidad restringida son vitales.</td>
                <td>
                  <ul>
                    <li>Medios del cliente</li>
                    <li>Transmisiones electrónicas de clientes.</li>
                    <li>Información del producto generada para el cliente por las actividades de producción de la compañía según lo especificado por el cliente.</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <br />

        <p>
          En materia de protección de datos personales, se deberá cumplir lo dispuesto en La Ley Federal de Protección de Datos Personales en Posesión de los Particulares, se considerarán los datos personales, medidas de seguridad y el tratamiento de datos personales.
        </p>
        <p>
        Todo tratamiento de datos personales que efectúen las áreas de cada dirección, deberán justificar con un fin concreto, lícito, explícito y legítimo, relacionados con las atribuciones que la normatividad le aplique.
        </p>
        <p>
        Todas las áreas de la empresa que traten con datos personales, deberán justificar su uso con un fin concreto, lícito, explícito y legítimo, relacionado con las atribuciones que la normatividad le aplique. Deberán de considerar y mantener las medidas de seguridad de carácter administrativo, físico y técnico para la protección de los datos personales, que permitan protegerlos contra daño, pérdida, alteración, destrucción o su uso, acceso o tratamiento no autorizado, así como cuidar su confidencialidad, integridad y disponibilidad.
        </p>
        <br />

        <h4>9.1. ACCESOS</h4>
        <br />
        <p>
        Los únicos autorizados para acceder a los servicios de información de AESIR CORPORATION, serán los que cuenten con los privilegios derivados de su cargo.
        <br />
        El área de _____ será responsable de establecer controles de seguridad para gestionar las cuentas de los usuarios en la empresa, sea a través de la firma de una responsiva o de cualquier otro documento análogo que busque proteger la información dada. Los controles de acceso a los servicios de información, se asignan tomando en cuenta el rol y el perfil del usuario.
        <br />
        La entrega de las contraseñas de equipos, correo electrónico y otros se hará de manera confidencial, únicamente con el usuario correspondiente.
        <br />
        El área de Recursos Humanos, gestionará las bajas o cambios del personal para que el área de _______ ejecute el cambio o revocación de los derechos de acceso.
        <br />
        Los externos que cuenten con accesos deberán de estar bajo el mando del jefe de área a la que corresponden por sus funciones, este será responsable de notificar las bajas o cambios de funciones del personal, para que se ejecute el cambio o revocación de los derechos de acceso.
        </p>
        <br />

        <h4>9.3 RESPONSABILIDAD DEL USUARIO</h4>
        <br />
        <p>
        Todo el personal de AESIR CORPORATION será responsable de su contraseña, que tendrá carácter de confidencial y debe mantenerse secreta.
        <br />
        Solo deberán tener acceso a los correos y cuentas de la empresa los usuarios autorizados, con la cuenta asignada para tal efecto; en ningún caso deben acceder usando una cuenta diferente, sin que esto este justificado.
        <br />
        Queda estrictamente prohibido acceder a sitios:
        <ul>
          <li>Redes sociales y mensajería instantánea.</li>
          <li>Pornográficos.</li>
          <li>Descarga de música en sus diversos formatos: Mp3, RA, CDA etc.</li>
          <li>Descarga de video en sus diversos formatos como.MOV, JPEG, AVI, RAF, RA, etc.</li>
          <li>Escucharla radio y ver televisión por Internet.</li>
          <li>Salvo en aquellos casos que se justifique, contando con el visto bueno del responsable de área correspondiente.</li>
          <li>Demás sitios que sean distractores de las actividades propias de la posición.</li>
          <li>Queda prohibido el uso de cuentas personales para la realización las actividades laborales, bajo ninguna circunstancia se tolerará.</li>
        </ul>
        </p>
        <br />

        <h4>10. POLÍTICA DE SEGURIDAD FÍSICA Y AMBIENTAL</h4>
        <br />
        <p>
        El objetivo del presente apartado busca asegurar que solo los usuarios autorizados tengan acceso a las instalaciones de procesamiento de información. AESIR CORPORATION será responsable de definir un espacio físico y seguro, que cuente con la protección necesaria para el resguardo de la información y que asegure su almacenamiento.
        <br />
        Habrá lugares a los cuales solo el personal autorizado podrá acudir a la información, con esto se busca limitar el acceso a las áreas de seguridad, si no cuentan con una autorización expresa no podrán acceder.
        <br />
        Si las personas autorizadas permiten el acceso a personal ajeno, deberán justificar las razones del por qué.
        </p>
        <br />

        <h4>10.1. SEGURIDAD DE OFICINA</h4>
        <br />
        <p>
        El área de recursos humanos proporcionará a cada empleado un espacio físico asignado que cuente con mobiliario protegido para el resguardo de información física, además de proporcionarle un acceso controlado para el uso de las instalaciones de acuerdo a sus funciones dentro de la empresa.
        </p>
        <br />

        <h4>10.2 PROTECCIÓN CONTRA AMENAZAS EXTERNAS Y AMBIENTALES</h4>
        <br />
        <p>
        Se establecerán perímetros de seguridad física con el fin de proteger áreas que contengan información crítica de la empresa, deberán estar definidos e identificados.
        </p>
        <br />

        <h4>11. SEGURIDAD DE LOS EQUIPOS</h4>
        <br />
        <p>
        Todo equipo que almacene, procese o transmita información esencial para la operación de AESIR CORPORATION, deber ser protegido para disminuir el riesgo de amenazas del exterior.
        <br />
        El cableado debe cumplir con las especificaciones del fabricante para minimizar errores físicos, no debe estar expuesto a condiciones ambientales que aceleren su deterioro.
        </p>
        <br />

        <h4>11.1 MANTENIMIENTO DE LOS EQUIPOS</h4>
        <br />
        <p>
        Todo activo de información debe contar con programas de soporte y mantenimiento para garantizar su correcto funcionamiento y disponibilidad, el área de TI deberá validar que el mantenimiento que se lleve a cabo, sea realizado por personal capacitado. Además guardará un registro de todos los mantenimientos preventivos y correctivos efectuados en los equipos.
        <br />
        En el caso de que el equipo de Cómputo presente alguna falla o mal funcionamiento, se deberá registrar la incidencia en el tablero de la plataforma Monday llamado “Incidencias Soporte” (____________) para dar seguimiento y solución a cada caso, quedando estrictamente prohibido que los usuarios abran o traten de arreglar los equipos.
        <br />
        Las incidencias serán catalogadas de acuerdo a su tipo (Internet, Plataformas y Equipos de cómputo), también se asignará un nivel de prioridad conforme a lo siguiente:
        <ul>
          <li>Urgente (Tiempo de atención: Mismo día) El equipo no funciona por completo.</li>
          <li>Necesaria (Tiempo de atención: Tres días máximo) El equipo funciona deficientemente.</li>
          <li>Baja (Tiempo de atención: Una semana máximo) El equipo funciona, se requiere agregar algo, tanto en hardware como software.</li>
          <li>El tiempo de resolución en caso de necesitarse recursos económicos estará sujeto a la aprobación y planeación de Finanzas.</li>
        </ul>
        </p>
        <br />

        <h4>11.2 SEGURIDAD DE LOS EQUIPOS Y ACTIVOS FUERA DE LAS INSTALACIONES</h4>
        <br />
        <p>
        Todo equipo que almacene, procese, transmita información crítica de AESIR CORPORATION debe operar dentro de las instalaciones de la empresa, los equipos que salgan de las instalaciones deben apegarse a las medidas mencionadas anteriormente.
        </p>
        <br />

        <h4>12. POLÍTICA DE SEGURIDAD EN LAS OPERACIONES</h4>
        <br />
        <p>
        La presente política tiene por objetivo salvaguardar la confidencialidad, integridad y disponibilidad de la información que es procesada mediante los distintos mecanismos de comunicación y operación de los sistemas de información.
        <br />
        Los procesos de operación de las áreas de la empresa establecerán la forma de resguardo de la información de su operación.
        <br />
        Queda estrictamente prohibido la instalación y utilización de software de que haga uso indiscriminado del ancho de banda de la red, por ejemplo: kazaja, Ares, Gato, Audio Galaxy, YouTube, y aplicaciones P2P, ver televisión o escuchar la radio haciendo mal uso del enlace a Internet.
        <br />
        El acceso a los archivos y carpetas contenidas en DropBox está restringido para miembros ajenos a la organización como medida de seguridad, por lo que, si requiere tener accesos adicionales, se habilitarán previa solicitud, justificación y autorización del responsable de área correspondiente.
        </p>
        <br />

        <h4>12.1. RESPALDO DE INFORMACIÓN </h4>
        <br />
        <p>
        El personal de AESIR CORPORATION es responsable de identificar la información que sea sensible para la operación de su área, debe dar aviso al área de TI para gestionar su respaldo y periodicidad.
        <br />
        Se deberán de implementar procedimientos para respaldar la información de la empresa, que sea de forma periódica el respaldo de la información que resida en los sistemas de la empresa, considerando su importancia. Además de lo anterior se debe de revisar y validar periódicamente la información respaldada, para evitar su deterioro.
        <br />
        Se mantendrá un registro actualizado con acceso controlado que contendrá los datos de todos los archivos respaldados.
        </p>
        <br />

        <h4>12.2 ALMACENAMIENTO DE INFORMACIÓN</h4>
        <br />
        <p>
        El área de IT deberá proporcionar y administrar espacio de almacenamiento suficiente para que las áreas puedan resguardar copia de su información.
        <br />
        Queda prohibido la utilización de recursos de almacenamiento institucional para archivos de uso personal o de diversión.
        <br />
        El área de IT debe contar con procedimientos y mecanismos de borrado o destrucción y de la información de AESIR CORPORATION, que ya no sea necesaria, ni por la operación, ni por requerimientos legales. La información que ya no sea utilizada deberá de ser eliminada de forma segura, siguiendo los criterios establecido por el área correspondiente a la que pertenece.
        </p>
        <br />

        <h4>13. POLÍTICA DE GESTIÓN DE INCIDENTES DE SEGURIDAD</h4>
        <br />
        <p>
        Esta política explica cómo se proporciona la información sobre la notificación de incidentes, quién es responsable de informar, responder e investigar y cómo se manejan.
        <br />
        Se aplica a todas las personas involucradas en un incidente real, sospechoso, amenazado o potencial que implica la pérdida de datos o una violación de la seguridad de la información.
        <br />
        Potencialmente, esto incluye a todo el personal, asociados y cualquier otra persona autorizada para usar las instalaciones y la información de AESIR CORPORATION, siguiendo los siguientes puntos:
        <br />
        La información de incidentes de seguridad será manejada adecuada y eficazmente, buscando que se minimice el impacto adverso a AESIR CORPORATION y el riesgo de pérdida de datos.
        <br />
        AESIR CORPORATION se asegurará de que:
        <ul>
          <li>Los incidentes se informen de manera oportuna y puedan ser adecuadamente investigados por las áreas internas correspondientes.</li>
          <li>Los accidentes son manejados por personal debidamente autorizado y calificado.</li>
          <li>Los niveles apropiados de manejo de incidentes están involucrados en la determinación de las acciones de respuesta.</li>
          <li>Los incidentes se registran y documentan.</li>
          <li>El impacto de los incidentes son entendidos para tomar medidas para evitar daños mayores.</li>
          <li>La evidencia se recopila, registra y mantiene en una forma que resista el escrutinio interno y externo.</li>
          <li>Los organismos externos o los interesados ​​son informados según sea necesario.</li>
          <li>Los incidentes se tratan de una manera oportuna y restaurando las operaciones.</li>
          <li>Los incidentes se revisan para identificar mejoras en las políticas y procedimientos.</li>
        </ul>
        <br />
        AESIR CORPORATION proporcionará información a través de canales de capacitación y comunicación, lo que explica cómo se debe informar la seguridad de la información en los accidentes y fomentará la notificación de todos los incidentes, ya sean reales, sospechosos, amenazados o potenciales.
        <br />
        Los equipos de seguridad de la información supervisarán y revisarán los incidentes para identificar incidentes recurrentes y áreas de riesgo. El proceso de revisión se utilizará para identificar requisitos para políticas nuevas o modificadas, para actualizar el registro de riesgos de AESIR CORPORATION e identificar cualquier otro control relevante.
        <br />
        Si se produce un incidente de seguridad de la información que requiere una respuesta coordinada en AESIR CORPORATION o el incidente tiene un posible interés externo o mediático, se activará el Plan de Continuidad Comercial de AESIR CORPORATION.
        <br />
        AESIR CORPORATION realizará pruebas periódicas de los procedimientos de manejo de seguridad de la información para mantener y mejorar la conciencia del personal sobre los procedimientos y las acciones requeridas.
        </p>
        <br />

        <h4>14. POLÍTICA DE CIFRADO  </h4>
        <br />
        <p>
        Esta Política de cifrado especifica cómo se cifrarán los datos en función de su clasificación de sensibilidad. La clasificación de información o datos afectará el nivel de encriptación requerido para el almacenamiento y la transmisión
        <br />
        Visión de conjunto
        <br />
        Esta Política de cifrado ayudará a garantizar que los datos estén protegidos adecuadamente para sus necesidades. Todos los miembros del personal y cualquier persona que tenga la custodia de los datos de la organización deben estar familiarizados con esta política, especialmente aquellos que son los dueños comerciales de los datos.
        <br />
        Propósito
        <br />
        Esta Política de cifrado tiene por objeto garantizar que toda la información o los datos se cifren correctamente en función de su clasificación de sensibilidad, de modo que estén debidamente protegidos contra la exposición no autorizada, los cambios no autorizados o inexactos, o la pérdida. Los algoritmos de cifrado que se utilizan para cifrar datos deben haber demostrado ser efectivos y haber recibido un escrutinio público significativo. También se deben cumplir las normas que rigen el uso de la tecnología de cifrado.
        <br />
        Alcance
        <br />
        Esta política de cifrado se aplica a todos los datos o la información almacenada en formato electrónico. Se aplica a cualquier dato o información almacenada o utilizada por la organización. Esta Política de cifrado se aplica a todos los empleados de la organización, incluidos los contratistas y los trabajadores a tiempo parcial. Esta política es efectiva a partir de la fecha de emisión y no caduca a menos que sea reemplazada por otra política.
        <br />
        Definiciones
        <br />
        <ul>
          <li>Algoritmo de propiedad: Un algoritmo de cifrado que se mantiene privado y no se hace público.</li>
          <li>Cifrado asimétrico: También conocido como cifrado de clave pública / clave privada, el cifrado asimétrico utiliza una clave para cifrar los datos y otra clave para descifrar los datos.</li>
          <li>Cifrado simétrico: La misma clave se usa tanto para cifrar los datos como para descifrarlos.</li>
        </ul>
        <br />
        Requisitos
        <ul>
          <li>Toda comunicación y almacenamiento de datos debe cumplir con los requisitos legales relacionados con la privacidad, el almacenamiento y la comunicación.</li>
          <li>No se utilizarán algoritmos de cifrado patentados sin la revisión y aprobación de expertos a través del Director de Seguridad de la Información.</li>
          <li>Todos los datos confidenciales en computadoras móviles o dispositivos de almacenamiento de memoria móvil deben estar encriptados, excepto durante el tiempo que se están utilizando.</li>
          <li>Es posible que las computadoras que tienen datos confidenciales almacenados no transmitan señales inalámbricas de texto sin formato. Las conexiones inalámbricas deben estar seguras y encriptadas con un protocolo mínimo de WAPII de acuerdo con las políticas de encriptación e inalámbricas.</li>
          <li>Todos los datos que se almacenan o transmiten con un nivel de sensibilidad de seguridad confidencial o superior deben cifrarse durante el almacenamiento y la transmisión en una red confiable o no confiable.</li>
          <li>Los certificados digitales no deben ser válidos por más de 5 años.</li>
          <li>Un tercero de confianza (Autoridad de Certificación) debe verificar los certificados y las claves criptográficas utilizadas para las transacciones sean seguras antes de que se puedan intercambiar datos con requisitos de seguridad confidenciales o superiores.</li>
          <li>Las firmas digitales se pueden usar para asegurarse de que una fuente confiable proporcione información. Los protocolos aprobados utilizados con las firmas digitales deben ser establecidos por el Director de Seguridad de la Información.</li>
        </ul>

        <br />
        Las marcas de tie
        </p>
        <br />

        <div>
          <Table responsive striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Nivel de sensibilidad de datos</th>
                <th>Protocolo de encriptación</th>
                <th>Longitud mínima de clave</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ultra secreto</td>
                <td>AES</td>
                <td>256</td>
              </tr>
              <tr>
                <td>Secreto</td>
                <td>AES</td>
                <td>256</td>
              </tr>
              <tr>
                <td>Confidential</td>
                <td>AES</td>
                <td>128 (256 cuando sea posible)</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <ul>
          <li>mpo se usan con las transacciones y se incluyen en el contenido del mensaje cuando el contenido está firmado digitalmente. Las marcas de tiempo consideran las zonas horarias según sea necesario.</li>
          <li>Cuando se intercambian datos confidenciales por datos confidenciales de mayor nivel, la información debe cumplir con los requisitos mínimos de encriptación y debe intercambiarse por medios físicamente seguros siempre que sea posible.</li>
          <li>Antes de que se pueda abrir un canal seguro a un tercero, se debe usar una clave criptográfica certificada para establecer el canal.</li>
          <li>Antes de que se pueda abrir un canal seguro a un tercero, se debe usar una clave criptográfica certificada para establecer el canal.</li>
        </ul>

        <p>Técnicas de cifrado aprobadas</p>
        <br />

        <p><strong>Protocolos de cifrado aceptados y longitudes de clave para el almacenamiento simétrico de   datos confidenciales</strong></p>
        <p><strong>Protocolos de cifrado aceptados y longitudes de clave para la transmisión asimétrica de datos confidenciales</strong></p>
        
        <p>Las longitudes o protocolos de clave de cifrado deben ser aprobados por el Director de Seguridad de la Información.</p>
        <br />

        <h4>GESTIÓN DE CLAVES</h4>
        <p>
          <ul>
            <li>Todas las claves digitales deben almacenarse en forma cifrada utilizando un protocolo y una técnica de cifrados aprobados.</li>
            <li>Cualquier clave digital que se sospeche que está comprometida debe revocarse lo antes posible y las partes afectadas deben ser informadas.</li>
            <li>Las claves criptográficas deben distribuirse utilizando un mecanismo seguro que no sea el mismo que el de los canales que se abrirán (sin conexión).</li>
            <li>Deben existir políticas para permitir la generación, revocación, distribución, cambio, almacenamiento, uso y destrucción de claves digitales.</li>
          </ul>
        </p>
        <p>Deben crearse procedimientos para saber cuándo</p>
        <br />

        <div>
          <Table responsive striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Nivel de sensibilidad de datos</th>
                <th>Cifrado Protocolo</th>
                <th>Longitud mínima de clave</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ultra secreto</td>
                <td>RSA</td>
                <td>3072</td>
              </tr>
              <tr>
                <td>Secreto</td>
                <td>RSA</td>
                <td>3072</td>
              </tr>
              <tr>
                <td>Confidential</td>
                <td>RSA</td>
                <td>2048</td>
              </tr>
            </tbody>
          </Table>
        </div>
        <br />

        <ul>
          <li>se necesita la renovación de la clave raíz.</li>
          <li>La creación de las claves raíz debe hacerse con la formalidad requerida adecuada con testigos.</li>
          <li>Se debe crear una declaración sobre cómo se realizan las prácticas de certificación. Esta declaración describe las prácticas de la autoridad de certificación (CA) y la autoridad de registro (RA).</li>
        </ul>
        <br />

        <p>Otras políticas</p>
        <ul>
          <li>Política de sensibilidad de la información: especifica cómo se manejan, almacenan y transmiten los datos a través del ciclo de vida del proyecto en función de su categoría de sensibilidad.</li>
          <li>Política de certificación de datos: especifica cómo se clasifican los datos por su sensibilidad.</li>
        </ul>
        <br />

        <h4>APLICACIÓN</h4>
        <p>
        Dado que el cifrado de datos es importante para proteger los datos almacenados o transmitidos por la organización para evitar daños, los empleados que violen esta política a propósito pueden estar sujetos a medidas disciplinarias que pueden incluir la denegación de acceso, penalidades legales y / o despido. Cualquier empleado que esté al tanto de cualquier violación de esta política debe informarlo a su supervisor u otro representante autorizado.
        </p>
        <br />

        <h4>OTROS REQUERIMIENTOS</h4>
        <ul>
          <li>Deben redactarse políticas y procedimientos para la gestión de claves.</li>
          <li>Se debe crear y publicar una lista de aplicaciones de tecnologías de encriptación y longitudes mínimas de clave.</li>
        </ul>
        <br />

        <p>Algunas comparaciones de protocolos</p>
        <ul>
          <li>SHA1: produce un resumen de 160 bits, requiere más recursos que MDA5</li>
        </ul>








    </Container>
    </div>
    );
  }
  
export default InformationSecurityPolicies;