
import React from 'react';
import { Row, Col, Image, Card } from 'react-bootstrap';
import './blog.css'

function SidePosts() {
  
  return (
    <div className="article-main">
      <Row className="padd-xs-bottom">
        <Col className="no-padd" lg={4}>
          <Image src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359018/aesir/Blog%20Aesir/ERM_01_pfmysd.png" rounded />
        </Col>
        <Col lg={7}>
          <Card>
            <Card.Body className="no-padd">
              <Card.Title>Brainvire Developed Marketplace for Leading Cybersecurity Provider with Odoo</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="padd-xs-bottom">
        <Col className="no-padd" lg={4}>
          <Image src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359018/aesir/Blog%20Aesir/ERM_02_nlryjn.png" rounded />
        </Col>
        <Col lg={7}>
          <Card>
            <Card.Body className="no-padd">
              <Card.Title>Odoo ahora es un unicornio. ¿Y qué?</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row >
        <Col className="no-padd" lg={4}>
          <Image src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359018/aesir/Blog%20Aesir/ERM_03_yekgve.png" rounded />
        </Col>
        <Col lg={7}>
          <Card>
            <Card.Body className="no-padd">
              <Card.Title>Extract data from SAP ERP and BW with Amazon AppFlow</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    
    </div>
  );
}

export default SidePosts;