import '../../../App.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function BotomSlide() {
  return (

    <div className="padd-space">
      <Container className="text-center justify-content-center">
        <h1>We transform startups into mature Tech Companies</h1>
        <Row className=" padd-sides padd-md-top mobile-white-space padd-xs-bottom">
          <Col xs={10} className="width-20-lg Custom-col-width ">
            <div className="Companies-steps-box bg-container-grey shadow">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632930157/aesir/research_b8anf2.png" alt="" />
              <h4>Research <br /> Industries</h4>
              <span>1</span>
            </div>
          </Col>
          <Col xs={10} className="width-20-lg Custom-col-width">
            <div className="Companies-steps-box bg-container-grey shadow">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632930157/aesir/create_onenkv.png" alt="" />
              <h4>Create <br /> Startups</h4>
              <span>2</span>
            </div>
          </Col>
          <Col xs={10} className="width-20-lg Custom-col-width">
            <div className="Companies-steps-box bg-container-grey shadow">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632930157/aesir/invest_htaomy.png" alt="" />
              <h4>Invest</h4>
              <span>3</span>
            </div>
          </Col>
          <Col xs={10} className="width-20-lg Custom-col-width">
            <div className="Companies-steps-box bg-container-grey shadow">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632930157/aesir/manage_qehpa7.png" alt="" />
              <h4>Manage <br /> Companies</h4>
              <span>4</span>
            </div>
          </Col>
          <Col xs={10} className="width-20-lg Custom-col-width">
            <div className="Companies-steps-box bg-container-grey shadow">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632930239/aesir/mature_vp6zez.png" alt="" />
              <h4>Mature Tech <br /> Companies</h4>
              <span>5</span>
            </div>
          </Col>
        </Row>
      </Container>
    </div>

  );
}

export default BotomSlide;