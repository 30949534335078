
import React from 'react';
import Container from 'react-bootstrap/Container';


function HumanRights() {
  return (
    <div className="padd-space padd-lg-top">
      <Container>

        <h1 className="dflex-al-center"><span className="point-des grey">.</span>Políticas De Derechos Humanos</h1>
        <br />
        <h4>POLÍTICAS DE HYPERPEOPLE S.A. DE C.V.</h4>
        <p>
          Nuestras políticas internas son un conjunto de directrices documentadas que establecen normas y procedimientos apropiados para regular el comportamiento de nuestros colaboradores. Permiten uniformar los valores, criterios y cultura en el seno de la empresa. Dictan, en definitiva, las conductas que esperamos de los colaboradores que se relacionan con nuestra empresa y el modo de desarrollarlas. También las conductas indeseadas y la forma de evitarlas o actuar frente a ellas.
        </p>
        <p>
          Las políticas de este documento son obligatorias a toda persona que participe como colaborador en  HYPERPEOPLE S.A. DE C.V., Estamos comprometidos a respetar los Derechos Humanos y la integridad de todos nuestros colaboradores, es por ello que formalizamos nuestro compromiso con los derechos humanos y laborales reconocidos en la Ley Federal del Trabajo vigente de los Estados Unidos Mexicanos y ante la Comisión Nacional de Derechos Humanos alineados con nuestros principios. Creemos y reconocemos que los negocios solo pueden prosperar en las sociedades donde se protegen y respetan los derechos humanos y   laborales, para contribuir de manera positiva sobre ellos.
        </p>
        <br />

        <h4>POLÍTICAS DE DERECHOS HUMANOS</h4>
        <p>
          El objetivo de esta política es expresar la activa y continuada determinación que tiene la “Empresa” de cumplir con su responsabilidad de respetar y promover los estándares reconocidos en materia de derechos humanos. Constituyen derechos humanos aquellos derechos fundamentales, libertades y estándares de conducta y trato a los que todas las personas tienen derecho y respetarlos es un valor esencial que forma parte intrínseca del modo en que desarrollamos nuestra actividad, por lo que se tendrá cero tolerancias si se llega a incumplir con la misma.
        </p>
        <h6>COMPROMISO</h6>
        <p>
          Esta Política recoge el compromiso y las responsabilidades en relación con todos los derechos humanos, y en especial con los que afectan a nuestra actividad empresarial y a las operaciones desarrolladas por los colaboradores. La “Empresa” promueve el respeto de los derechos humanos e integridad de sus colaboradores en todas sus relaciones comerciales, prestando particular atención a las situaciones de conflicto y de alto riesgo.
        </p>
        <p>
          La determinación es:
          <ul>
            <li>Cumplir con todas las leyes aplicables y respetar los Derechos Humanos en todos aquellos lugares donde opera.</li>
            <li>Fomentar la concientización de colaboradores y socios comerciales en cuestiones relativas a Derechos Humanos.</li>
            <li>Valorar los riesgos actuales y potenciales y su impacto en nuestras operaciones.</li>
            <li>Adoptar medidas adecuadas para la prevención, mitigación y, cuando resulte procedente, la remediación de impactos adversos.</li>
            <li>Proporcionar acceso a mecanismos de queja internos y regulados por las Autoridades para ejecutar un Plan de Acción inmediato.</li>
            <li>Informando continua y transparentemente acerca de nuestros esfuerzos en esta área.</li>
          </ul>
        </p>
        <br />

        <h6>PRÁCTICAS LABORALES</h6>
        <p>Rechazo al trabajo forzoso u obligatorio y al trabajo infantil</p>
        <br />
        <p>
          La Empresa rechaza el uso de cualquier forma de trabajo forzoso u obligatorio y no confisca dinero ni documentos de identidad al inicio de la relación laboral con el objeto de retener a los colaboradores en contra de su voluntad. Se respetan los derechos de los niños y rechaza el uso de mano de obra infantil.
        </p>
        <br />

        <p>Rechazo a la discriminación laboral</p>
        <br />
        <p>
          La Empresa rechaza toda forma de discriminación y mantiene el compromiso de velar por que todos sus colaboradores sean tratados con respeto hacia su diversidad, promoviendo así mismo la igualdad de oportunidades al establecerse la relación laboral.
        </p>
        <br />

        <p>Seguridad y salud laboral</p>
        <br />
        <p>
          La Empresa tiene el compromiso de velar por que en los lugares de trabajo se respeten las mejores condiciones de seguridad y salud laboral, se promueve la difusión y refuerzo de una cultura de la seguridad, desarrollando la concienciación sobre el riesgo, y fomenta el comportamiento responsable por parte de sus colaboradores compartiendo información relevante.
        </p>
        <br />

        <p>Condiciones de trabajo justas y favorables</p>
        <br />
        <p>
          Con el fin de mantener un entorno de trabajo positivo y respetuoso, la Empresa rechaza toda forma de acoso —ya sea verbal, físico, sexual o psicológico—amenaza o intimidación en el lugar de trabajo.Con el fin de mantener un entorno de trabajo positivo y respetuoso, la Empresa rechaza toda forma de acoso —ya sea verbal, físico, sexual o psicológico—amenaza o intimidación en el lugar de trabajo.
        </p>
        <br />

        <p>Confidencialidad</p>
        <br />
        <p>
        Tolerancia cero con la corrupción en todas sus formas, tanto directa como indirecta. La Empresa respeta la confidencialidad y el derecho a la intimidad de todas las personas con quienes interactúa, y mantiene el compromiso de utilizar correctamente toda la información y los datos que obran en su poder.
        </p>
        <br />

        <p>SOPORTE</p>
        <br />
        <p>
          Le damos importancia a la disposición de un remedio efectivo siempre que se produzcan efectos relativos a los derechos humanos a través de mecanismos de reclamación mediante la empresa y mantenemos el compromiso de mejorar la capacidad de nuestros directivos para identificar y responder efectivamente ante estas inquietudes. Vemos la diversidad y la inclusión como una fortaleza. Respetamos lo que cada individuo aporta a nuestro equipo, incluyendo antecedentes, educación, género, raza, etnicidad, estilos de trabajo y pensamiento, orientación sexual, identidad y/o expresión de género, estatus veterano, origen religioso, edad, generación, discapacidad, experiencia cultural y habilidad técnica. Nos comprometemos a respetar la privacidad de las personas, incluyendo colaboradores y clientes. Seguimos los principios de privacidad e implementamos prácticas razonables y apropiadas. Cumplimos y esperamos que nuestros proveedores y otros socios comerciales cumplan con las leyes que promueven condiciones de trabajo seguras, leyes que prohíben el trabajo forzado, prohibiciones de acoso y discriminación ilegal; y leyes que garantizan la libertad de asociación y el derecho a participar en negociaciones colectivas.
        </p>
        <br />

        <p>SOPORTE LEGAL</p>
        <br />
        <p>
        Toda persona tiene todos los derechos y libertades proclamados en estas Políticas sin distinción alguna de raza, color, sexo, idioma, religión, opinión política o de cualquier otra índole, origen nacional o social, posición económica, nacimiento o cualquier otra condición. Además, no se hará distinción alguna fundada en la condición política, jurídica o internacional del país o territorio de cuya jurisdicción dependa una persona, tanto si se trata de un país independiente, como de un territorio bajo administración fiduciaria, no autónomo o sometido a cualquier otra limitación de soberanía. (art. 2 de la Declaración Universal de Derechos Humanos)
        </p>
        <p>
        La Constitución Política de los Estados Unidos Mexicanos consagra el mandato de no discriminación. Queda prohibida toda discriminación motivada por origen étnico o nacional, el género, la edad, las discapacidades, la condición social, las condiciones de salud, la religión, las opiniones, las preferencias sexuales, el estado civil o cualquier otra que atente contra la dignidad humana y tenga por objeto anular o menoscabar los derechos y libertades de las personas. (art. 1 de la Constitución Política de los Estados Unidos Mexicanos)
        </p>
        <p>
        Todas las autoridades, en el ámbito de sus competencias, tienen la obligación de promover, respetar, proteger y garantizar los derechos humanos. (art. 1 de la Constitución Política de los Estados Unidos Mexicanos). La Constitución Política de los Estados Unidos Mexicanos consagra el mandato de no discriminación. Queda prohibida toda discriminación motivada por origen étnico o nacional, el género, la edad, las discapacidades, la condición social, las condiciones de salud, la religión, las opiniones, las preferencias sexuales, el estado civil o cualquier otra que atente contra la dignidad humana y tenga por objeto anular o menoscabar los derechos y libertades de las personas. (art. 1 de la Constitución Política de los Estados Unidos Mexicanos)
        </p>
        <p>
        Las disposiciones de esta Ley son de orden público y de interés social. El objeto de la misma es prevenir y eliminar todas las formas de discriminación que se ejerzan contra cualquier persona en los términos del Artículo 1 de la Constitución Política de los Estados Unidos Mexicanos, así como promover la igualdad de oportunidades y de trato. (art. 1 de la Ley Federal para Prevenir y Eliminar la Discriminación en México)
        </p>
        <p>
        Queda prohibida toda práctica discriminatoria que tenga por objeto impedir o anular el reconocimiento o ejercicio de los derechos y la igualdad real de oportunidades. A efecto de lo anterior, se consideran como conductas discriminatorias: Fracc. 1 impedir el acceso a la educación pública o privada, así como a becas e incentivos para la permanencia en los centros educativos, en los términos de las disposiciones aplicables. (art. 9 de la Ley Federal para Prevenir y Eliminar la Discriminación en México)
        </p>
        <p>
        Queda prohibida toda práctica discriminatoria que tenga por objeto impedir o anular el reconocimiento o ejercicio de los derechos y la igualdad real de oportunidades. A efecto de lo anterior, se consideran como conductas discriminatorias: Fracc. 3 prohibir la libre elección de empleo, o restringir las oportunidades de acceso, permanencia y ascenso en el mismo. (art. 9 de la Ley Federal para Prevenir y Eliminar la Discriminación en México)
        </p>
        <p>
        Queda prohibida toda práctica discriminatoria que tenga por objeto impedir o anular el reconocimiento o ejercicio de los derechos y la igualdad real de oportunidades. A efecto de lo anterior, se consideran como conductas discriminatorias: Fracc. 4 establecer diferencias en la remuneración, las prestaciones y las condiciones laborales para trabajos iguales y Fracc. 5 limitar el acceso a los programas de capacitación y de formación profesional. (art. 9 de la Ley Federal para Prevenir y Eliminar la Discriminación en México)
        </p>
        <p>
        Queda prohibida toda práctica discriminatoria que tenga por objeto impedir o anular el reconocimiento o ejercicio de los derechos y la igualdad real de oportunidades. Fracc. 15: Ofender, ridiculizar o promover la violencia en los supuestos a que se refiere el artículo 4 de esta Ley a través de mensajes e imágenes en los medios de comunicación. (art. 9 de la Ley Federal para Prevenir y Eliminar la Discriminación en México)
        </p>
        <p>
        Para efectos de esta Ley se entiende por: a) Hostigamiento, el ejercicio del poder en una relación de subordinación real de la víctima frente al agresor en el ámbito laboral, que se expresa en conductas verbales, físicas o ambas; y b) Acoso sexual, una forma de violencia en la que, si bien no existe la subordinación, hay un ejercicio abusivo del poder que conlleva a un estado de indefensión y de riesgo para la víctima, independientemente de que se realice en uno o varios eventos. (art. 3o. Bis. La ley Federal del Trabajo)
        </p>
        <p>
        Son causas de rescisión de la relación de trabajo, sin responsabilidad para el patrón: VIII. Cometer el colaborador actos inmorales o de hostigamiento y/o acoso sexual contra cualquier persona en el establecimiento o lugar de trabajo; Fracción reformada DOF 30-11-2012 (art. 47 La ley Federal del Trabajo)
        </p>
        <p>
        Son causas de rescisión de la relación de trabajo, sin responsabilidad para el colaborador: II. Incurrir el patrón, sus familiares o cualquiera de sus representantes, dentro del servicio, en faltas de probidad u honradez, actos de violencia, amenazas, injurias, hostigamiento y/o acoso sexual, malos tratamientos u otros análogos, en contra del colaborador, cónyuge, padres, hijos o hermanos; Fracción reformada DOF 30-11-2012 (art. 51 La ley Federal del Trabajo)
        </p>
        <p>
        Son obligaciones de los patrones: XXXI. Implementar, en acuerdo con los colaboradores, un protocolo para prevenir la discriminación por razones de género y atención de casos de violencia y acoso u hostigamiento sexual, así como erradicar el trabajo forzoso e infantil; Fracción adicionada DOF 01-05-2019 (art. 132 La ley Federal del Trabajo)
        </p>
        <p>
        Queda prohibido a los patrones o a sus representantes: XII. Realizar actos de hostigamiento y/o acoso sexual contra cualquier persona en el lugar de trabajo; Fracción adicionada DOF 30- 11-2012 y XIII. Permitir o tolerar actos de hostigamiento y/o acoso sexual en el centro de trabajo; Fracción adicionada DOF 30-11-2012 (art. 133 La ley Federal del Trabajo)
        </p>
        <p>
        Quedan exceptuados de agotar la instancia conciliatoria, cuando se trate de conflictos inherentes a: Discriminación en el empleo y ocupación por embarazo, así como por razones de sexo, orientación sexual, raza, religión, origen étnico, condición social o acoso u hostigamiento sexual; (art. 685 La ley Federal del Trabajo)
        </p>
        <p>
        Se impondrá multa, por el equivalente a: VI. De 250 a 5000 Unidades de Medida y Actualización, al patrón que cometa cualquier acto o conducta discriminatoria en el centro de trabajo; al que realice actos de hostigamiento sexual o que tolere o permita actos de acoso u hostigamiento sexual en contra de sus colaboradores, así como al que viole las prohibiciones establecidas en las fracciones IV y V del artículo 133 de la Ley, o lo dispuesto en el artículo 357, segundo y tercer párrafo de ésta. (art. 994 La ley Federal del Trabajo)
        </p>
        <br />

        <p>
          PROTOCOLO GENERAL
          <ul>
            <li>Establecer medidas de prevención informativas respecto al tema.</li>
            <li>Definir los mecanismos para orientar y acompañar a las víctimas.</li>
            <li>Señalar las instancias competentes para resolver estas conductas.</li>
            <li>Contar con un registro de los casos sucedidos.</li>
            <li>Primer contacto: la víctima podrá informar al área interna correspondiente de dar seguimiento (RH).</li>
            <li>Atención especializada: se debe identificar el tipo de apoyo o intervención.</li>
            <li>Investigación: se atenderá el caso e informará a las instancias oficiales correspondientes.</li>
            <li>Sanción: se impondrá la sanción administrativa y legal correspondiente.</li>
            <li>Gravedad: determinada dependiendo el caso (llamada de atención, acta administrativa, recisión de contrato justificado o demanda).</li>
          </ul>
        </p>
        <br />

        <p>PROTOCOLO HAS (Hostigamiento y Acoso Sexual)</p>
        <br />
        <p>
          <strong>Es importante identificar los tipos y clases de hostigamiento y acoso sexual, para poder responder de la manera más indicada, por lo que a continuación se mencionan las clasificaciones:</strong>
          <ul>
            <li>CHANTAJE. - ofrecimientos de carácter sexual, a cambio de un beneficio laboral.</li>
            <li>AMBIENTE LABORAL HOSTIL. - actos tendientes a la humillación o intimidación. En este no es necesaria una relación superior respecto del sujeto afectado.</li>
            <li>FÍSICO- toda clase de tocamientos, invasión de espacio personal, caricias, etc.; que incomoden al receptor por su connotación de índole sexual.</li>
            <li>VERBAL- son las insinuaciones de carácter sexual a través de mensajes, llamadas, notas, correos, preguntas respecto de preferencias o estilo de vida, invitaciones sugestivas, etc.</li>
            <li>NO VERBAL- actuares que involucren el compartir imágenes sugestivas o sexuales, estos o movimientos de clara intención sexual, espiar, piropos ofensivos, sonidos o silbidos, persecuciones, etc.</li>
          </ul>
        </p>


      </Container>
    </div>
  );
}

export default HumanRights;