import '../../../App.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Slider from "react-slick";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function Companies() {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 4,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };
  return (
    <div className="bg-container-grey">
      <Container className="padd-space padd-md-bottom">
        <h1 className="dflex-al-center"><span className="point-des red">.</span> Companies</h1>
      
        <Slider {...settings}>
          <a href="https://ubbitt.com/" target="_blank" rel="noopener noreferrer">
            <div className="companie-slide">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632249670/aesir/COMPANIES_UBBITT_nl7n4b.png" alt="" />
              <h6>Digital Platform</h6>
            </div>
          </a>
          <a href="https://ansuz.com.mx/" target="_blank" rel="noopener noreferrer">
            <div className="companie-slide">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632249676/aesir/COMPANIES_ANSUZ_jhbnij.png" alt="" />
              <h6>Insurance</h6>
            </div>
          </a>
          <a href="https://arkedigital.com.mx/" target="_blank" rel="noopener noreferrer">
            <div className="companie-slide">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632263047/aesir/COMPANIES_ARKE_qkre6h.png" alt="" />
              <h6>Digital Agency</h6>
            </div>
          </a>
          <a href="https://boniviajes.com/" target="_blank" rel="noopener noreferrer">
            <div className="companie-slide">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632249673/aesir/COMPANIES_BONIVIAJES_krkzwd.png" alt="" />
              <h6>Travel</h6>
            </div>
          </a>
          <a href="http://eosdotbranch.com" target="_blank" rel="noopener noreferrer">
            <div className="companie-slide">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632249661/aesir/COMPANIES_EOS_nb9yi1.png" alt="" />
              <h6>Digital Agency</h6>
            </div>
          </a>
          
            <div className="companie-slide">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632249668/aesir/COMPANIES_SCINAPTECH_k17vyw.png" alt="" />
              <h6>Government Solutions</h6>
            </div>
          
          
            <div className="companie-slide">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632249652/aesir/COMPANIES_HYPERINFRA_pquzhs.png" alt="" />
              <h6>Construction</h6>
            </div>
      
            <div className="companie-slide">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632249654/aesir/COMPANIES_TERAHASH_yvgw0z.png" alt="" />
              <h6>Crypto Mining</h6>
            </div>
          
            <div className="companie-slide">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632249663/aesir/COMPANIES_AKSECURITY_cip1it.png" alt="" />
              <h6>Security</h6>
            </div>
          
          <a href="https://www.hyperionentertainment.com/" target="_blank" rel="noopener noreferrer">
            <div className="companie-slide">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632249650/aesir/COMPANIES_HYPERION_cd8coj.png" alt="" />
              <h6>Entertainment</h6>
            </div>
          </a>
          <a href="https://kuantrum.com/" target="_blank" rel="noopener noreferrer">
            <div className="companie-slide">
              <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632249658/aesir/COMPANIES_KUANTRUM_nrbtxt.png" alt="" />
              <h6>Ecommerce</h6>
            </div>
          </a>
        </Slider>
      </Container>
    </div>
  );
}

export default Companies;