import React from 'react'
import {Switch, Route} from 'react-router-dom'
//import ScrollToTop from './components/ScrollToTop'

import Inicio from './components/home/body';
import Homeblog from './components/blog/home-blog';
import CreateArticle from './components/blog/create-article';
import Article from './components/blog/blog-components/article';

//pages info
import InfoCodeEtic from './components/legal-pages/etic-code';
import HumanRights from './components/legal-pages/human-rights';
import EnviromentalPolicy from './components/legal-pages/environmental-policy';
import AntiCorruptionPolicy from './components/legal-pages/anti-corruption-policy';
import InformationSecurityPolicies from './components/legal-pages/informationsecurity-policies';
import CookiesPolicy from './components/legal-pages/cookies-policy';
import PhilosofyP from './components/legal-pages/phylosofy'




const Routes = () => {
    return(
        <Switch>
          <Route exact path="/" component={Inicio} />

          <Route path="/blog" component={Homeblog} />
          <Route path="/crate-article" component={CreateArticle} />
          <Route path="/article" component={Article} />

          <Route path="/anti-corruption-policy" component={AntiCorruptionPolicy} />
          <Route path="/code-of-ethics" component={InfoCodeEtic} />
          <Route path="/cookies-policy" component={CookiesPolicy} />
          <Route path="/enviromental-policy" component={EnviromentalPolicy} />
          <Route path="/information-security-policies" component={InformationSecurityPolicies} />
          <Route path="/human-rights" component={HumanRights} />
          <Route path="/philosofy" component={PhilosofyP} />
        </Switch>
    )
};

export default Routes