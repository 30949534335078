
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';


//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAlIXhoEREHYBa7s6ijsCuBU8Kc9Zfq02k",
  authDomain: "ouroboros2021-644fc.firebaseapp.com",
  databaseURL: "https://ouroboros2021-644fc-default-rtdb.firebaseio.com",
  projectId: "ouroboros2021-644fc",
  storageBucket: "ouroboros2021-644fc.appspot.com",
  messagingSenderId: "720482721074",
  appId: "1:720482721074:web:d28151dfbd5b93100d4b2b",
  measurementId: "G-5S8W3MS14Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

//const analytics = getAnalytics(appFirebase);

export default db