import React from 'react'
import { Container, Image, Card, Col, Row, Button  } from 'react-bootstrap';
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaRegHeart, FaTwitterSquare } from "react-icons/fa";
import SidePosts from './side-posts';
import './blog.css'

function Article() {
  return (
    <div className="padd-space padd-lg-top article-main">
      <Container>
        <Row>
          <Col xs={12} lg={8}>
            <Card >
              <Card.Img variant="top" src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359019/aesir/Blog%20Aesir/SEO_01_olvrmt.png" />
              <Card.Body>
              <Card.Subtitle className="mb-2 text-muted">Por Jeff Kauflin | Sep 23, 2021</Card.Subtitle>
                <Card.Title>Qué es SEO y cómo mejorar tu posición en buscadores</Card.Title>
                <Card.Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </Card.Text>
              </Card.Body>
            </Card>
            <br />
            <hr />
            <Row>
              <Col lg={10}>
                <Button className="margin-sides" variant="light">Business</Button>
                <Button className="margin-sides" variant="light">Marketing</Button>
                <Button className="margin-sides" variant="light">SEO</Button>
              </Col>
              <Col lg={2}>
                <Button className="custom-btn-likes"><FaRegHeart /> <span>350</span></Button>
              </Col>
            </Row>
            <hr />
            <div className="List-no-style padd-space social-article">
              <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/AESIR-Corporation-103167094687916"><span className="s-icon"><FaFacebookSquare /></span></a>
              <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/aesircorp_"><span className="s-icon"><FaTwitterSquare /></span></a>
              <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/aesircorporation/"><span className="s-icon"><FaInstagramSquare /></span></a>
              <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/%C3%A6sir-corporation"><span className="s-icon"><FaLinkedin /></span></a>
            </div>
            <br />

            <Row className="custom-border">
              <Col className="no-padd" lg={5}>
                <Image src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359018/aesir/Blog%20Aesir/COMPANIES_02_vufbgr.png" rounded />
              </Col>
              <Col lg={7}>
                <Card>
                  <Card.Body>
                    <Card.Subtitle className="mb-2 text-muted">Por Jeff Kauflin | Sep 23, 2021</Card.Subtitle>
                    <Card.Title>What is WeChat’s dream? </Card.Title>
                    <Card.Text>
                      Some quick example text to build on the card title.
                    </Card.Text>
                    <br />
                    <div className="List-no-style padd-space ">
                      <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/aesircorp_"><span className="s-icon"><FaTwitterSquare /></span></a>
                      <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/aesircorporation/"><span className="s-icon"><FaInstagramSquare /></span></a>
                      <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/%C3%A6sir-corporation"><span className="s-icon"><FaLinkedin /></span></a>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col className="only-desktop" lg={4}>
            <Button className="marg-xs-bottom btn-color-main full-width" variant="secondary" size="lg">
              ÚLTIMOS POSTS
            </Button>
            <SidePosts />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Article