import '../../../App.css';
import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';



function Slide() {
  return (
    <>

      <Container className="padd-md-bottom">      
        <Carousel className="carousel-desk" variant="dark">
          <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632417274/aesir/MAPA_20210923_xm9srs.png"
                alt="First slide"
              />
              <div className="Carousel-caption_top">
                <Carousel.Caption className="text-left animate__animated animate__slideInRight">
                  <div>
                    <h2>By incubating Digital Ecosystems</h2>
    
                    <h4>we want to create a better future for humankind</h4>
    
                    <h3>with high probabilities of becoming unicorns</h3>
                  </div>
                </Carousel.Caption>
    
                <Carousel.Caption className="text-right animate__animated animate__slideInLeft">
                  <div>
                    <h2>We are a Global Corporation</h2>
    
                    <h4>with diversify interests and investments through</h4>
                    <h3>Spain, Canada and Latin America</h3>
                  </div>
                </Carousel.Caption>
              </div>
              <Carousel.Caption className="animate__animated animate__slideInUp">
                <h4>With better technology we are sure together
                  <br />
                  we'll create a better future for humankind
                </h4>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
    
    

        <div className="carousel-mobile padd-lg-top-mobile App">
          <div className="padd-space">
            <h2 className="">By incubating Digital Ecosystems</h2>
            <br />
            <h4>we want to create a better future for humankind <span className="custom-text-slider">with high probabilities of becoming unicorns</span></h4>
            
          </div>
          <Carousel variant="dark">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632930157/aesir/mapa_mobile_vdyrqy.png"
                alt="First slide"
              />
            </Carousel.Item>
          </Carousel>

          <div className="padd-space">
            <h4>
              With better technology we are sure together
              we'll create a better future for humankind
            </h4>
          </div>
        </div>

      </Container>

    </>

  );
}
  
export default Slide;