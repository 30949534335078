
import React from 'react';
import Container from 'react-bootstrap/Container';


function CookiesPolicy() {
  return (
    <div className="padd-space padd-lg-top">
    <Container>

      <h1 className="dflex-al-center"><span className="point-des blue">.</span>Políticas De Cookies</h1>
        <br />
      <div>
        <h2>ÍNDICE</h2>
        <ul>
          <li>¿QUÉ SON LAS COOKIES?</li>
          <li>COORKIES UTILIZADAS</li>
          <li>ACEPTACIÓN</li>
        </ul>
      </div>

      <p>
      Este sitio web utiliza cookies ________ (verificar si las que se utilizan son propias o de terceros) para mejorar la experiencia de los usuarios, conservar sus preferencias, ofrecer servicios relacionados con las mismas y obtener datos anónimos agregados que permitan disponer de estadísticas de uso.
      </p>
      <br />

      <h4>1. ¿QUÉ SON LAS COOKIES?</h4>
      <p>
      Las cookies son archivos de texto que contienen pequeñas cantidades de información y que se descargan en el dispositivo del usuario cuando visita una página web. Posteriormente, las cookies se envían de vuelta a la página web de origen (cookies de origen) o a otras páginas web (cookies de terceros). 
      </p>
      <p>
      Las cookies permiten que una página web reconozca el dispositivo de un usuario, facilitando la navegación, recordando preferencias, mejorando la experiencia de usuario y, en general, ayudando a ofrecerle información y servicios relacionados con sus intereses.
      </p>
      <p>
      Una cookie suele contener el nombre de la página web de la que procede, la "vida" de la cookie (esto es, el periodo de tiempo que la cookie permanecerá en su dispositivo) y un valor, que suele ser un número único generado aleatoriamente.
      </p>
      <br />

      <h4>2. COOKIES UTILIZADAS. (revisar cuales aplican)</h4>
      <p>
      Cookies técnicas: Son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.
      </p>
      <p>
      Cookies de personalización: son aquellas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etcétera.
      </p>
      <p>
      Cookies de análisis: Son aquellas que bien tratadas por nosotros o por terceros colaboradores, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.
      </p>
      <p>
      Cookies publicitarias: son aquellas que, bien tratadas por nosotros o por terceros, nos permiten gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay en la página web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web. Para ello podemos analizar sus hábitos de navegación en Internet y podemos mostrarle publicidad relacionada con su perfil de navegación.
      </p>
      <p>
      Cookies de publicidad comportamental: son aquellas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.
      </p>
      <p>
      Cookies de seguimiento con plug-in social: Estas cookies se utilizan para hacer un seguimiento de personas que son usuarios [o no] de redes sociales para el análisis de datos de estudios de mercado y el desarrollo de productos.
      </p>
      <p>
      Cookies analíticas: Las cookies analíticas recogen datos acerca de la utilización de la Página por su parte y nos permiten mejorar su funcionamiento. Por ejemplo, las cookies analíticas nos muestran cuáles son las secciones más visitadas de la Página, nos ayudan a llevar un registro de las dificultades que usted experimenta con la Página y nos indican si nuestra publicidad es o no eficaz. Todo ello nos permite conocer las pautas de utilización de la Página en general y no limitándonos a un único usuario.
      </p>
      <br />

      <h4>3. ACEPTACIÓN</h4>
      <p>
      El Usuario acepta, por la utilización de este Website, el tratamiento de la información recabada en la forma y con los fines anteriormente mencionados. Y, asimismo, reconoce estar al tanto de la posibilidad de rechazar el tratamiento de tales datos o información rechazando el uso de Cookies mediante la selección de la configuración apropiada a tal fin en su navegador. Si bien esta opción de bloqueo de cookies en su navegador puede no permitirle el uso pleno de todas las funcionalidades del Website.
      </p>
      <p>
      Las cookies indicadas no recopilan información personal sobre los usuarios del sitio web.
      </p>
      <p>
      Recuerde que usted puede eliminarlas en cualquier momento, o rechazarlas antes de su instalación configurando el programa de navegación que utiliza. Por favor, visite la página de ayuda de su navegador para aprender cómo gestionar las cookies en su equipo.
      </p>


    </Container>
    </div>
    );
  }
  
export default CookiesPolicy;