
import React, {useState, useEffect} from 'react';
import {Container, Button, Row, Col, Image, Card} from 'react-bootstrap';
import { Link } from "react-router-dom";
//import SFeedFabook from './blog-components/slide-feed-facebook';
//import SFeedTwitt from './blog-components/slide-feed-twitter';
import SliderBlog from './blog-components/slider-blog';
import SidePosts from './blog-components/side-posts'

import { collection, getDocs } from 'firebase/firestore';
import db from '../../lib/firebaseConfig'

function Homeblog() {

  useEffect(() => {
    //const obetenerDatos = async() =>{
      //const posts = await getDocs(collection(db, "posts"));
      //console.log(posts.docs);
      //posts.forEach((doc) => {
        //console.log(doc.data());
      //})
    //}
    //obetenerDatos()
  }, [])
  return (
    <div className="padd-space padd-lg-top body-blog">
      <Container>
        <SliderBlog />
        <Row>
          <Col xs={12} lg={8}>

            <div className="articles-blog ">
              <Button className="marg-xs-bottom btn-color-main" variant="secondary" size="lg">
                FINANCE
              </Button>
              <a href="https://www.forbes.com/sites/jeffkauflin/2021/09/23/shares-of-money-transfer-fintech-remitly-rise-13-in-ipo-valuing-it-at-78-billion/?sh=478e7e2eecaf" target="_blank" rel="noopener noreferrer">
              <Row>
                <Col lg={5}>
                  <Image src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359018/aesir/Blog%20Aesir/FINANCE_01_mhuguk.png" rounded />
                </Col>
                <Col lg={7}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Shares Of Money-Transfer Fintech Remitly Rise 13% In IPO, Valuing It At $7.8 Billion</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">Por Jeff Kauflin | Sep 23, 2021</Card.Subtitle>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              </a>
              <hr />
              <a href="https://www.forbes.com/sites/jonathanponciano/2021/09/24/crypto-markets-wipe-out-150-billion-in-value-within-hours-of-chinas-latest-bitcoin-ban-whats-next/?sh=2aa095ec4e4f" target="_blank" rel="noopener noreferrer">
              <Row>
                <Col lg={5}>
                  <Image src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359018/aesir/Blog%20Aesir/FINANCE_02_qanb1i.png" rounded />
                </Col>
                <Col lg={7}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Crypto Markets Wipe Out $150 Billion In Value Within Hours Of China’s Latest ‘Bitcoin Ban’—What’s Next?</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">Por Jeff Kauflin | Sep 23, 2021</Card.Subtitle>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              </a>
              <hr />

              <Button className="marg-xs-bottom btn-color-main" variant="secondary" size="lg">
                COMPANIES
              </Button>
              <a href="https://blog.hubspot.es/sales/empresas-unicornio" target="_blank" rel="noopener noreferrer">
              <Row>
                <Col lg={5}>
                  <Image src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359018/aesir/Blog%20Aesir/COMPANIES_01_hpwtav.png " rounded />
                </Col>
                <Col lg={7}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Qué son las empresas unicornio (incluye ejemplos de América Latina y España)</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">Por Jeff Kauflin | Sep 23, 2021</Card.Subtitle>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              </a>
              <hr />
              <a href="https://blog.wechat.com/2019/03/18/what-is-wechats-dream-wechat-founder-allen-zhang-explains/" target="_blank" rel="noopener noreferrer">
              <Row>
                <Col lg={5}>
                  <Image src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359018/aesir/Blog%20Aesir/COMPANIES_02_vufbgr.png" rounded />
                </Col>
                <Col lg={7}>
                  <Card>
                    <Card.Body>
                      <Card.Title>What is WeChat’s dream? WeChat founder Allen Zhang explains</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">Por Jeff Kauflin | Sep 23, 2021</Card.Subtitle>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              </a>
              <hr />

              <Row className="no-hr-mobile">
                <Col lg={6}>
                  <a href="https://www.forbes.com/sites/michaeldelcastillo/2021/09/20/blockchains-biggest-businesses-forbes-blockchain-50-call-for-2022-nominations/?sh=33842cd81a1a" target="_blank" rel="noopener noreferrer">
                  <Row className="min-height">
                    <Col className="no-padd" col={5}>
                      <Image variant="top" src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359017/aesir/Blog%20Aesir/COMPANIES_03_nsssvr.png" />
                    </Col>
                    <Col col={7}>
                      <Card>
                        <Card.Body>
                          <Card.Title>Blockchain’s Biggest Businesses: Forbes Blockchain 50 Call For 2022 Nominations</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">Sep 23, 2021</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  </a>
                  <hr />
                  <a href="https://blog.wechat.com/2019/03/18/what-is-wechats-dream-wechat-founder-allen-zhang-explains/" target="_blank" rel="noopener noreferrer">
                  <Row className="min-height">
                    <Col className="no-padd" col={5}>
                      <Image variant="top" src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359017/aesir/Blog%20Aesir/COMPANIES_05_osgdcw.png" />
                    </Col>
                    <Col col={7}>
                      <Card>
                        <Card.Body>
                          <Card.Title>What is WeChat’s dream? WeChat founder Allen Zhang explains</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">Sep 23, 2021</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  </a>
                  <hr />
                  <a href="https://www.nokia.com/networks/insights/ecosystems/becoming-an-ecosystem-player/?did=D000000005I7&gclid=CjwKCAjwndCKBhAkEiwAgSDKQRAKMAgD9_tWVOMMyuxShVCPjmBdNbJrx3dEU3CBgvu8-ceVIbV4ohoCzgYQAvD_BwE" target="_blank" rel="noopener noreferrer">
                  <Row className="min-height">
                    <Col className="no-padd" col={5}>
                      <Image variant="top" src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359017/aesir/Blog%20Aesir/COMPANIES_07_ff4mxn.png" />
                    </Col>
                    <Col col={7}>
                      <Card>
                        <Card.Body>
                          <Card.Title>Becoming an ecosystem player: Are you ready?</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">Sep 23, 2021</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  </a>
                <br />
                </Col>

                <Col lg={6}>
                  <a href="https://blog.rappi.com/como-nacio-rappi/" target="_blank" rel="noopener noreferrer">                  
                  <Row className="min-height rapi-card">
                    <Col className="no-padd" col={5}>
                      <Image variant="top" src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359017/aesir/Blog%20Aesir/COMPANIES_04_moghbx.png" />
                    </Col>
                    <Col col={7}>
                      <Card>
                        <Card.Body>
                          <Card.Title>Cómo nació Rappi explicado por su fundador Simon Borrero</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">Sep 23, 2021</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  </a>                  
                  <hr />
                  <a href="https://www.nasdaq.com/articles/accidental-entrepreneur%3A-how-kelly-phenicie-is-embracing-slow-fashion-and-sustainable" target="_blank" rel="noopener noreferrer">
                  <Row className="min-height">
                    <Col className="no-padd" col={5}>
                        <Image variant="top" src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359019/aesir/Blog%20Aesir/COMPANIES_06_lzsrga.png" />
                    </Col>
                    <Col col={7}>
                      <Card>
                        <Card.Body>
                          <Card.Title>How Kelly Phenicie is Embracing Slow Fashion and Sustainable Manufacturing</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">Sep 23, 2021</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  </a>
                  <hr />
                  <a href="https://www.facebook.com/formedia/blog/facebook-partner-voices-ufc" target="_blank" rel="noopener noreferrer">
                  <Row className="min-height">
                    <Col className="no-padd" col={5}>
                      <Image variant="top" src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359017/aesir/Blog%20Aesir/COMPANIES_08_juv6mu.png" />
                    </Col>
                    <Col col={7}>
                      <Card>
                        <Card.Body>
                          <Card.Title>Facebook Partner Voices: UFC</Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">Sep 23, 2021</Card.Subtitle>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  </a>
                  <br />
                </Col>
              </Row>
                <hr />
              <Button className="marg-xs-bottom btn-color-main" variant="secondary" size="lg">
                ERM
              </Button>
              <Col lg={12}>
                <a href="https://www.odoo.com/es_ES/blog/customer-reviews-6/brainvire-developed-marketplace-for-leading-cybersecurity-provider-with-odoo-798" target="_blank" rel="noopener noreferrer">
                <Row>
                  <Col xs={12} col={5}>
                    <Image variant="top" src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359018/aesir/Blog%20Aesir/ERM_01_pfmysd.png" />
                  </Col>
                  <Col xs={12} col={7}>
                    <Card>
                      <Card.Body>
                        <Card.Title>Brainvire Developed Marketplace for Leading Cybersecurity Provider with Odoo</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">Por Jeff Kauflin | Sep 23, 2021</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                </a>
                <hr />
                <a href="https://www.odoo.com/es_ES/blog/nuestro-blog-5/odoo-ahora-es-un-unicornio-y-que-784" target="_blank" rel="noopener noreferrer">
                <Row>
                  <Col xs={12} col={5}>
                    <Image variant="top" src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359018/aesir/Blog%20Aesir/ERM_02_nlryjn.png" />
                  </Col>
                  <Col xs={12} col={7}>
                    <Card>
                      <Card.Body>
                        <Card.Title>Odoo ahora es un unicornio. ¿Y qué?</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">Por Jeff Kauflin | Sep 23, 2021</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                </a>
                <hr />
                <a href="https://aws.amazon.com/es/blogs/awsforsap/extract-data-from-sap-erp-and-bw-with-amazon-appflow/" target="_blank" rel="noopener noreferrer">
                <Row>
                  <Col xs={12} col={5}>
                    <Image variant="top" src="https://res.cloudinary.com/duooqqgsu/image/upload/v1633359018/aesir/Blog%20Aesir/ERM_03_yekgve.png" />
                  </Col>
                  <Col xs={12} col={7}>
                    <Card>
                      <Card.Body>
                        <Card.Title>Extract data from SAP ERP and BW with Amazon AppFlow</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">Por Jeff Kauflin | Sep 23, 2021</Card.Subtitle>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                </a>
                
              </Col>
            </div>

          </Col>
          <Col className="only-desktop" xs={12} lg={4}>
            <Button className="marg-xs-bottom btn-color-main full-width" variant="secondary" size="lg">
              ÚLTIMOS POSTS
            </Button>
            <SidePosts />
          </Col>

          
        </Row>


        {/* <Link to="/crate-article">Crear articulo</Link> */}
      </Container>
    </div>
  );
}

export default Homeblog;