import '../../../App.css';
import React from 'react';
import Container from 'react-bootstrap/Container';

import Slider from "react-slick";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function ClientsSec() {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 5,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]

  };
  return (
    <div className="bg-container-grey">
      <Container className="padd-space padd-md-bottom">
        <h1 className="dflex-al-center"><span className="point-des red">.</span> Clients</h1>
      
        <Slider className="slider-clients" {...settings}>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253791/aesir/CLIENT_HAVAS_dwv5ca.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253792/aesir/CLIENT_UNIVERSAL_uwdviu.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253795/aesir/CLIENT_HONDA_wnepje.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253797/aesir/CLIENT_PEPSI_zbndqp.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253799/aesir/CLIENT_FEMSA_ayab0t.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253802/aesir/CLIENT_KIA_aoaqn4.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253726/aesir/CLIENT_STARCOM_ckumu5.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253731/aesir/CLIENT_LINIO_zzkv4m.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253737/aesir/CLIENT_LEOBURNETT_bg8qig.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253739/aesir/CLIENT_SPOTIFY_kvfkx0.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253741/aesir/CLIENT_CITIBANAMEX_jlysth.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253743/aesir/CLIENT_CMIC_eiwdjk.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253745/aesir/CLIENT_MEC_lnlay3.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253748/aesir/CLIENT_UBER_aislbp.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253750/aesir/CLIENT_CHAMPIONS_sehzmi.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253748/aesir/CLIENT_UBER_aislbp.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253750/aesir/CLIENT_CHAMPIONS_sehzmi.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253753/aesir/CLIENT_VW_yxwi7q.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253755/aesir/CLIENT_PARAMOUNT_ecwftn.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253757/aesir/CLIENT_20THFOX_fjbytd.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253762/aesir/CLIENT_CRUZROJA_y6rgz4.png" alt="" />
          </div>

          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253764/aesir/CLIENT_CDMX_vp8qzd.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253766/aesir/CLIENT_NESCAFE_cwdu94.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253768/aesir/CLIENT_WTW_io833m.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253771/aesir/CLIENT_ATT_tz6w6i.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253773/aesir/CLIENT_BIMBO_a7ds7k.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253775/aesir/CLIENT_CIE_rp6uru.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253777/aesir/CLIENT_NESTLE_fyipy3.png" alt="" />
          </div>

          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253779/aesir/CLIENT_FONACOT_opybrg.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253781/aesir/CLIENT_JJ_kij05y.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253783/aesir/CLIENT_MOVISTAR_svdgyr.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253785/aesir/CLIENT_WWE_t9tr91.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253789/aesir/CLIENT_NELSON_ps9lzq.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253804/aesir/CLIENT_MAPFRE_trofex.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253806/aesir/CLIENT_WB_ponbe3.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253809/aesir/CLIENT_CMI_xxdavz.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253816/aesir/CLIENT_PGBBQ_mqc2fq.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253821/aesir/CLIENT_HUAWEI_xwvx1n.png" alt="" />
          </div>

          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253824/aesir/CLIENT_CARSO_pqbmei.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253826/aesir/CLIENT_SHAZAM_hikyqo.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253827/aesir/CLIENT_IPCMEDIABRANDS_qayrpm.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253829/aesir/CLIENT_SAMSUNG_tqpvgp.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253831/aesir/CLIENT_INFONAVIT_ddzxml.png" alt="" />
          </div>
          <div className="c-box-img">
            <img src="https://res.cloudinary.com/duooqqgsu/image/upload/v1632253834/aesir/CLIENT_RUZ_ywvqfh.png" alt="" />
          </div>
        </Slider>
      </Container>
    </div>
  );
}

export default ClientsSec;