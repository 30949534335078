
import React from 'react';
import Container from 'react-bootstrap/Container';


function EnviromentalPolicy() {
  return (
    <div className="padd-space padd-lg-top">
      <Container>

        <h1 className="dflex-al-center"><span className="point-des red">.</span>Política Ambiental</h1>
          <br />
          <div>
            <h2>ÍNDICE</h2>
            <ul>
              <li>PROPÓSITO</li>
              <li>ALCANCE </li>
              <li>ENERGÍA </li>
              <li>EDUCACIÓN AMBIENTAL</li>
              <li>REDUCCIÓN EN USO DE INSUMOS</li>
              <li>USO EFICIENTE Y RESPONSABLE DE LOS RECURSOS HÍDRICOS</li>
            </ul>
          </div>

          <p>
          <strong>AESIR CORPORATION,</strong> se compromete a realizar prácticas operativas seguras en materia de medio ambiente, a través de la integración de principios, programas y prácticas relacionadas con el mismo en cada una de las operaciones que realiza, así como dentro de cada área de la empresa.
          </p>
          <p>
          La presente política se va a dividir en 4 áreas enfocadas a su uso en la empresa y que son: Energía, Educación Ambiental, Reducción en Uso de Insumos y Uso Eficiente y Responsable de los Recursos Hídricos
          </p>
          <p><strong>PROPÓSITO </strong></p>
          <p>
            AESIR CORPORATION, a través de esta política busca ratificar el compromiso con el medio ambiente, mediante el establecimiento de principios que orientan a la Compañía para apoyar y mejorar su desempeño ambiental. Esta política servirá como un referente para todos los empleados, socios, proveedores, clientes y colaboradores en general, promoviendo al compromiso de cumplir con los siguientes ámbitos de actuación:
            <ul>
              <li>Respeto por el entorno económico, social y medioambiental.</li>
              <li>Prevenir, reducir y eliminar en la manera de la posibilidad la afección ambiental que pueda derivarse de las actividades que se desarrollen.</li>
              <li>Identificar y controlar los aspectos ambientales asociados a las actividades administrativas, de gestión y servicios.</li>
              <li>Cumplir con las disposiciones legales que existan en materia ambiental.</li>
              <li>Promover el reciclaje.</li>
              <li>Establecer anualmente los objetivos y metas ambientales y evaluar el grado de avance conseguido respecto a años anteriores.</li>
              <li>Dar publicidad y difundir la política ambiental para el conocimiento y respeto de la misma ante la sociedad y con los que laboran en la empresa.</li>
            </ul>
          </p>
          <br />

          <p><strong>ALCANCE </strong></p>
          <p>
          Esta Política es aplicable a todos los colaboradores de AESIR CORPORATION, así como a todas las partes relacionadas y grupos de interés, entendidos estos como: clientes, proveedores, accionistas, inversionistas, aliados, empleados, contratistas, subcontratistas; y en general a todos aquellos con quienes de manera directa o indirectamente se establezca alguna relación comercial, contractual, laboral o de cooperación.
          </p>
          <br />

          <p><strong>ENERGÍA</strong></p>
          <p>
            AESIR CORPORATION se compromete a minimizar el impacto nocivo que proviene del consumo de sus actividades. Por ello se asignará a las áreas correspondiente, el establecer un programa de consumo sustentable, con el fin de reducir el consumo.
            <ul>
              <li>Se realizarán anualmente campañas de formación y sensibilización enfocas en el ahorro de consumo de energía del personal de la empresa, se enviarán comunicados a los correos institucionales del personal, donde se comunicarán recomendaciones y datos sobre la importancia del ahorro de energía.</li>
              <li>Se utilizarán focos, lámparas y equipos electrónicos de bajo consumo eléctrico.</li>
              <li>Se moderará la intensidad de la luz en las zonas que no sea tan necesaria y se revisarán continuamente los niveles de iluminación, así como se utilizarán los aires acondicionados sólo cuando sean necesarios.</li>
              <li>El área de soporte técnico realizará el cambio de equipos que después de una valoración y revisión, se determine que no hacen uso eficiente de la energía.</li>
              <li>El personal de la empresa desconectará los sistemas eléctricos que no se estén usando al momento de terminar sus actividades.</li>
              <li>Se organizarán los puestos de trabajo para poder aprovechar al máximo la luz natural.</li>
            </ul>
          </p>
          <br />

          <p><strong>EDUCACION AMBIENTAL</strong></p>
          <p>
            AESIR CORPORATION elaborará un plan de comunicación y educación ambiental en los ámbitos que apliquen para la empresa, dirigido a los que colaboren en la empresa, buscando siempre la transmisión y difusión de las buenas prácticas ambientales de modo constante, culminando en la mejora continua en la aplicación de los puntos mencionados anteriormente.
          </p>
          <br />

          <p><strong>REDUCCIÓN EN USO DE INSUMOS</strong></p>
          <p>
            El uso de insumos dentro de la empresa buscará reducirse para disminuir la degradación de la naturaleza. Se seguirán los siguientes puntos:
            <ul>
              <li>Uso adecuado de equipos, con el fin de evitar el consumo de energía excesivo o daños en los equipos, que potencialmente terminarán generando residuos, siguiendo lo anterior se prolongará la vida útil de los mismos.</li>
              <li>Disminuir el uso de material publicitario en papel, promoviendo la comunicación por medios digitales, ya sean por correo electrónico o medios alternativos, buscando que se reduzca el consumo de papel y tintas y así se generen menos residuos en general.</li>
              <li>Se buscará reducir el consumo de papel, uno de los principales insumos de oficina, buscando concientizar en la revisión de los trabajos a imprimir y su importancia, optando por el almacenamiento digital y la reutilización de hojas.</li>
            </ul>
          </p>
          <br />

          <p><strong>USO EFICIENTE Y RESPONSABLE DE LOS RECURSOS HÍDRICOS</strong></p>
          <p>
            AESIR CORPORATION, es consciente de la importancia del agua como recurso natural, por lo que buscará la optimización de su consumo para disminuir el impacto ambiental negativo.
            <ul>
              <li>Se realizarán campañas de sensibilización, información y capacitación tendientes a la disminución del consumo de agua entre el personal del municipio.</li>
              <li>Utilizar aparatos sanitarios de bajo consumo de agua.</li>
              <li>Programar controles de calidad y cantidad de las fuentes de agua.</li>
            </ul>
          </p>
          <br />

          <p>
          Todos los que integran AESIR CORPORATION asumen estos principios y es responsabilidad de cada uno llevarlos a la práctica, mediante el impulso a la mejora ambiental y la asignación de los recursos necesarios para asegurar la exitosa implantación de esta política ambiental.
          </p>

      
      </Container>
    </div>
    );
  }
  
  export default EnviromentalPolicy;